import { Table } from "antd";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import React, { useMemo } from "react";
import { getNumberFilterSorter } from "../../utilities/TableFilterSorters";
import { CheckOutlined } from "@ant-design/icons";
dayjs.extend(duration)
dayjs.extend(relativeTime)

const PectyTableExpand = ({ record }) => {

    const tableData = useMemo(() => {
        console.log(record.offers)
        return record.offers?.map(offer => ({ ...offer, IsBuyBoxWinner: offer.sellerId === record.buyboxSellerId }))
    }, [record])

    console.log(tableData)

    const columns = useMemo(() => ([
        {
            title: 'Seller',
            dataIndex: 'sellerName',
            key: 'sellerName',
            width: "150px",
        },
        {
            title: 'Type',
            dataIndex: 'fba',
            key: 'fba',
            width: "50px",
            render: (val) => val ? "FBA" : "FBM"
        },
        {
            title: 'Price',
            dataIndex: 'priceTotal',
            key: 'priceTotal',
            width: "100px",
            ...getNumberFilterSorter()
        },
        {
            title: 'Quantity',
            dataIndex: 'inventoryCount',
            key: 'inventoryCount',
            width: "100px",
            ...getNumberFilterSorter()
        },
        {
            title: 'Condition',
            dataIndex: 'condition',
            key: 'condition',
            width: "100px",
            ...getNumberFilterSorter()
        },
        {
            title: 'BB',
            dataIndex: 'IsBuyBoxWinner',
            key: 'BB',
            width: "40px",
            render: (value) =>
                value ? (
                    <CheckOutlined style={{ width: "100%" }} />
                ) : null
        },
    ]), [])

    return (
        <Table
            rowKey="dateProcessed"
            bordered={true}
            columns={columns}
            scroll={{ y: "68vh" }}
            sticky
            size="small"
            tableLayout={"fixed"}
            dataSource={tableData}
        />
    );
};

export default PectyTableExpand;
