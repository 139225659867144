import { Card, Col, Row, Space, Statistic, Timeline } from "antd";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import React, { useContext } from "react";
import SellerContext from "../../../services/SellerContext";
import { sellerIds } from "../../../services/UtilityService";
import PriceChart from "../PriceChart";
dayjs.extend(duration)
dayjs.extend(relativeTime)

const PricingTab = ({ data, showWinnersOnly }) => {
    const sellerMap = useContext(SellerContext);

    const styles = {
        card: {
          maxHeight: "100%"
        },
        cardBody: {
          height: 260,
          maxHeight: 260,
          overflow: "auto"
        },
        ordersCardBody: {
          height: 325,
          maxHeight: 325,
          overflow: "auto"
        },
    };

    return (
        <Row gutter={[8, 8]}>
            {/* First row */}
            <Col span={4}>
                <Card bordered={true} style={styles.card} bodyStyle={styles.cardBody}>
                    <Space direction="vertical">
                        <Statistic title="FBA Competitors" value={data.FBACompetitors} />
                        <Statistic title="FBM Competitors" value={data.FBMCompetitors} />
                        <Statistic title="Classification (Path)" value={data.Classification === 0 ? "Unknown" : data.Classification} />
                    </Space>
                </Card>
            </Col>
            <Col span={4}>
                <Card bordered={true} style={styles.card} bodyStyle={styles.cardBody}>
                    <Space direction="vertical">
                        <Statistic title="Suppressed" suffix={'h'} value={dayjs.duration(data.SuppressedTime, 'seconds').asHours().toFixed(2)} />
                        <Statistic title="BuyBox owned" suffix={'h'} value={dayjs.duration(data.YourBBTime, 'seconds').asHours().toFixed(2)} />
                        <Statistic title="Time coverage" suffix={'h'} value={dayjs.duration(data.TotalTimeDiff, 'seconds').asHours().toFixed(2)} />
                    </Space>
                </Card>
            </Col>
            <Col span={4}>
                <Card bordered={true} style={styles.card} bodyStyle={styles.cardBody}>
                    <Space direction="vertical">
                        <Statistic title="MaxBB" prefix={'$'} value={data.MaxBB} />
                        <Statistic title="MinBB" prefix={'$'} value={data.MinBB} />
                        <Statistic title="AverageBB" prefix={'$'} value={data.AverageBB?.toFixed(2)} />
                    </Space>
                </Card>
            </Col>
            <Col span={4}>

                <Card bordered={true} style={styles.card} bodyStyle={styles.cardBody}>
                    <Space direction="vertical">
                        <Statistic title="MaxLowest" prefix={'$'} value={data.MaxLowest} />
                        <Statistic title="MinLowest" prefix={'$'} value={data.MinLowest} />
                        <Statistic title="AverageLowest" prefix={'$'} value={data.AverageLowest?.toFixed(2)} />
                    </Space>
                </Card>
            </Col>
            <Col span={8}>
                <Card bordered={true} style={styles.card} bodyStyle={styles.cardBody}>
                    <Timeline mode={"left"}>
                        {data.PriceListsByTime?.map(priceTime => {
                            const key = dayjs(priceTime[0]).toISOString() + (Math.floor(Math.random() * 100))
                            const label = dayjs(priceTime[0]).toString()
                            const yourPrice = priceTime[1][sellerIds.Max]?.price
                            const winners = Object.entries(priceTime[1]).filter(([seller, data]) => data.isWinner)
                            let bbPrice
                            let bbOwner

                            if (winners.length > 1) {
                                bbPrice = winners.find(([seller, data]) => data.isWinner && data.fullfillment === "Amazon")[1]?.price
                                bbOwner = winners.find(([seller, data]) => data.isWinner && data.fullfillment === "Amazon")[0]
                            } else if (winners.length === 1) {
                                bbPrice = winners[0][1].price
                                bbOwner = winners[0][0]
                            }

                            return <Timeline.Item key={key} label={label}>
                                Your price: {yourPrice ? `$${yourPrice}` : "Unknown"}
                                <br></br>
              BB Price: {bbPrice ? `$${bbPrice}` : "Unknown"}
                                <br></br>
                                {bbOwner ? `Owns: ${sellerMap[bbOwner] ? sellerMap[bbOwner] : bbOwner}` : ""}
                            </Timeline.Item>
                        }
                        )}
                    </Timeline>
                </Card>
            </Col>

            {/* Second row */}
            <Col span={24}>
                {data.PriceListsByTime ? <PriceChart priceData={data.PriceListsByTime} showWinnersOnly={showWinnersOnly}></PriceChart> : null}
            </Col>
        </Row>
    );
};

export default PricingTab;
