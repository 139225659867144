import { Button, Col, Row, Spin } from "antd";
import { DownloadOutlined } from '@ant-design/icons';
import React, { useState } from "react";
import { ApiKey } from "../utility/constants";
import { Modal } from 'antd';

const Details = () => {
    const [loading, setLoading] = useState(false)

    const getReport = async (name) => {
        const url = `https://api.repricer.projectsuite.io/reports/${name}?key=${ApiKey}`;
        setLoading(true)
        fetch(url).then(resp => resp.text()).then(resp => {
            if (!resp.includes("error")) {
                const file = new Blob([resp], { type: "text/plain;charset=utf-8" });
                const a = document.createElement("a");
                document.body.appendChild(a);
                const url = URL.createObjectURL(file);
                a.href = url;
                a.download = `${name}_report.csv`;
                a.click();
                URL.revokeObjectURL(url);
                a.parentNode?.removeChild(a);
            } else {
                const errorResponse = JSON.parse(resp);
                Modal.error({
                    maskClosable: true,
                    okType: null,
                    title: errorResponse.error,
                    content: errorResponse.message
                })
            }
            setLoading(false);
        })
    }


    return (
        <Spin spinning={loading}>
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Button type="primary" icon={<DownloadOutlined />} size={"large"} block onClick={() => getReport('triples')}>
                        Triples
                        </Button>
                </Col>
                <Col span={12}>
                    <Button type="primary" icon={<DownloadOutlined />} size={"large"} block onClick={() => getReport('noshare')}>
                        No-Share
                        </Button>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Button type="primary" icon={<DownloadOutlined />} size={"large"} block onClick={() => getReport('undercutting')}>
                        Undercutting
                        </Button>
                </Col>
                <Col span={12}>
                    <Button type="primary" icon={<DownloadOutlined />} size={"large"} block onClick={() => getReport('threshold')}>
                        Threshold
                        </Button>
                </Col>
            </Row>
        </Spin>
    );
}

export default Details;
