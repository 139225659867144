import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { ApiKey, MaxUID } from "../utility/constants";
import { getToken } from "./UtilityService";
import * as dataForge from "data-forge";
dayjs.extend(duration)

export function getASINs() {
    return fetch(`https://api.repricer.projectsuite.io/stats/weekly/asins`, {
        method: 'GET',
        headers: {
            'Authorization': ApiKey,
        }
    })
        .then(res => res.json())
        .then(res => res.result);
}

export function getPectyCollections() {
    return fetch(`https://api.repricer.projectsuite.io/quantity/collections`, {
        method: 'GET',
        headers: {
            'Authorization': ApiKey,
        }
    })
        .then(res => res.json())
        .then(res => res.result.concat('legacy'))
}

export function getPectyASINs(collection = 'legacy') {
    return fetch(`https://api.repricer.projectsuite.io/quantity/${collection}/asins`, {
        method: 'GET',
        headers: {
            'Authorization': ApiKey,
        }
    })
        .then(res => res.json())
        .then(res => res.result)
        .then(res => res.map(data => ({'asin': data._id, 'title': data.title})));
}

export function getPectyData(asin, collection = 'legacy') {
    return fetch(`https://api.repricer.projectsuite.io/quantity/${collection}/${asin}`, {
        method: 'GET',
        headers: {
            'Authorization': ApiKey,
        }
    })
        .then(res => res.json())
        .then(res => res.result);
}

export function requestPectyData(asin, collection = 'random') {
    return fetch(`https://api.repricer.projectsuite.io/quantity/${asin}?collection=${collection}`, {
        method: 'POST',
        headers: {
            'Authorization': ApiKey,
            'Content-Type': 'application/json',
        }
    })
        .then(res => res.json())
}

export function requestMultiplePectyData(asins, collection = 'random') {
    return fetch(`https://api.repricer.projectsuite.io/quantity/${collection}/asins`, {
        method: 'POST',
        body: JSON.stringify({
            asins: asins
        }),
        headers: {
            'Authorization': ApiKey,
            'Content-Type': 'application/json',
        }
    })
        .then(res => res.json())
}

export function getDailySummaries() {
    if (ApiKey) {
        return fetch(`https://api.repricer.projectsuite.io/stats/daily`, {
            method: 'GET',
            headers: {
                'Authorization': ApiKey,
            }
        })
            .then(res => res.json())
            .then(data => data.map(summary => {
                const newSummary = { ...summary };
                newSummary.BBShare = parseFloat((100 * summary.YourBBTime / summary.TotalTimeDiff).toFixed(2));
                newSummary.WeightedBBShare = parseFloat((100 * summary.YourBBTimeWeighted / summary.TotalTimeDiffWeighted).toFixed(2));
                newSummary.SuppressedShare = parseFloat((100 * summary.SuppressedTime / summary.TotalTimeDiff).toFixed(2));

                if (Number.isNaN(newSummary.BBShare)) {
                    newSummary.BBShare = -1
                    newSummary.WeightedBBShare = -1
                    newSummary.SuppressedShare = -1
                }

                if (newSummary.MinBB === null) {
                    newSummary.MinBB = -1
                }

                if (newSummary.MaxBB === 0) {
                    newSummary.MaxBB = -1
                }
                return newSummary;
            }));
    } else {
        throw new Error('Authorization error')
    }

}

export function getSellerSnap(monthly = false) {
    if (ApiKey) {
        return fetch(`https://api.repricer.projectsuite.io/sellersnap${monthly ? '?monthly=true' : ''}`, {
            method: 'GET',
            headers: {
                'Authorization': ApiKey,
            }
        }).then(res => res.json()).then(res => res.reduce((sellerSnapObj, currItem) => {
            if (dayjs(sellerSnapObj[currItem.asin]?.extractTime ?? 0) < dayjs(currItem.extractTime)) {
                sellerSnapObj[currItem.asin] = currItem;
            }
            
            return sellerSnapObj;
        }, {}));
    } else {
        throw new Error('Authorization error')
    }
}

export function pushSellerSnap(updateData) {
    if (ApiKey) {
        return fetch(`https://api.repricer.projectsuite.io/sellersnap`, {
            method: 'POST',
            body: JSON.stringify({
                updateData: updateData
            }),
            headers: {
                'Authorization': ApiKey,
                'Content-Type': 'application/json',
            }
        }).then(res => res.json())
    } else {
        throw new Error('Authorization error')
    }
}

export function getDetailsWeekly(asin) {
    return fetch(`https://api.repricer.projectsuite.io/stats/weekly/${asin}`, {
        method: 'GET',
        headers: {
            'Authorization': ApiKey,
        }
    }).then(res => res.json());
}

export function getClassification(item) {
    return fetch(`https://api.repricer.projectsuite.io/classify`, {
        method: 'POST',
        body: JSON.stringify(item),
        headers: {
            'Authorization': ApiKey,
            'Content-Type': 'application/json',
        }
    }).then(res => res.json());
}

export function getOrdersSingleItem(asin, timeframe) {
    return fetch(`https://api.repricer.projectsuite.io/orders/${asin}?days=${timeframe}`, {
        method: 'GET',
        headers: {
            'Authorization': ApiKey,
        }
    }).then(res => res.json());
}

export function getOrdersAll(timeframe) {
    return fetch(`https://api.repricer.projectsuite.io/orders?days=${timeframe}`, {
        method: 'GET',
        headers: {
            'Authorization': ApiKey,
        }
    }).then(res => res.json());
}

export function getInventory(section) {
    return fetch(`https://api.repricer.projectsuite.io/inventory?section=${section}`, {
        method: 'GET',
        headers: {
            'Authorization': ApiKey,
        }
    }).then(res => res.json());
}

export function getOrdersPredictions() {
    return fetch(`https://api.repricer.projectsuite.io/predictions`, {
        method: 'GET',
        headers: {
            'Authorization': ApiKey,
        }
    }).then(res => res.json())
    .then(data => data.reduce((acc, curr) => {acc[curr.ASIN] = curr; return acc}, {}));
}

export function getSellerNames() {
    return fetch(`https://api.repricer.projectsuite.io/sellers`, {
        method: 'GET',
        headers: {
            'Authorization': ApiKey,
        }
    }).then(res => res.json());
}

export function getTitles() {
    return fetch(`https://api.repricer.projectsuite.io/titles`, {
        method: 'GET',
        headers: {
            'Authorization': ApiKey,
        }
    })
    .then(res => res.json())
    .then(data => data.reduce((acc, curr) => {acc[curr.ASIN] = curr.Title; return acc}, {}));
}

export function getPectyPredictions(collection) {
    return fetch(`https://api.repricer.projectsuite.io/quantity/${collection}/predict`, {
        method: 'GET',
        headers: {
            'Authorization': ApiKey,
        }
    })
    .then(res => res.json())
}

export function getPectyInsights(collection) {
    return fetch(`https://api.repricer.projectsuite.io/quantity/${collection}/insights`, {
        method: 'GET',
        mode: "cors",
        headers: {
            'Authorization': ApiKey,
        }
    })
    .then(res => res.json())
}

export function getSingleInsight(insight) {
    return fetch(`https://api.repricer.projectsuite.io/quantity/insights`, {
        method: 'POST',
        body: JSON.stringify({
            insight: insight
        }),
        headers: {
            'Authorization': ApiKey,
            'Content-Type': 'application/json',
        }
    })
    .then(res => res.json())
}

function getProjectSuiteData(token, db, collection) {
    return fetch(`https://api.projectsuite.io/repricer?firebase_token=${token}&db=${db}&collection=${collection}`).then(resp => resp.json()).then(resp => {
        if (resp.error) {
            throw new Error(resp.error)
        } else {
            return resp.result;
        }
    })
}

function parseBuySheetData(buyData) {
    const buyDataFrame = new dataForge.DataFrame(buyData).select((row) => {
        row['PurchasedQuantity'] = parseInt(row['QTY']);
        row['Cost'] = row['COST'] ? parseFloat(row['COST']) : 0;
        row['PurchasedDate'] = row['PURCHASEDATE'].split('/').length === 3 ? dayjs(row['PURCHASEDATE'], 'M/D/YYYY') : dayjs(row['PURCHASEDATE'], 'MM/DD');
        row['Supplier'] = row['SUPPLIER'];
        return row;
    })
        .dropSeries(['QTY', 'COST', 'LISTPRICE', 'PURCHASEDATE', 'TITLE', 'SUPPLIER'])
        .orderByDescending((row) => row['PurchasedDate']);

    const pivoted = buyDataFrame.pivot('ASIN', {
        'PurchasedQuantity': {
            PurchasedQuantity: (series) => series.sum(),
            LastRestockQty: (series) => series.first(),
        },
        'Cost': {
            Cost: (series) => parseFloat(series.average().toFixed(2)),
        },
        'PurchasedDate': {
            PurchasedDate: (series) => series.first().format('DD-MM-YYYY').toString(),
            DaysSinceRestock: (series) => Math.floor(dayjs.duration(dayjs().diff(dayjs(series.first()))).asDays())
        },
        'Supplier': {
            Supplier: (series) => series.last(),
        },
    })
        .withIndex((row) => row['ASIN']);

    return pivoted.toObject(row => row.ASIN, row => row);
}

export function getBuySheetData() {
    return getToken().then(token => getProjectSuiteData(token, 'buydata', MaxUID)).then(data => parseBuySheetData(data))
}