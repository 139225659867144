import React from "react";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import * as dataForge from "data-forge"
import dayjs from "dayjs";

export function CSVDownload({ data, isLoading, collection, title, children, ...buttonProps }) {
  const download = () => {
    if (!data || !data.length) return;

    const text = (new dataForge.DataFrame(data)).toCSV()

    const file = new Blob([text], { type: "text/plain;charset=utf-8" });
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = `${dayjs().format('MM_DD_YYYY')}_${collection}_${title}_data.csv`;
    a.click();
    URL.revokeObjectURL(url);
    a.parentNode?.removeChild(a);
  };

  return (
    <Button
      type="primary"
      onClick={download}
      {...buttonProps}
      disabled={!data || data.length === 0 || isLoading}
    >
      <DownloadOutlined /> {children ? children : 'Download CSV'}
    </Button>
  );
}
