import React from 'react';
import { StyledFirebaseAuth } from 'react-firebaseui';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../../services/AuthContext';
import firebase, { auth } from '../../utility/firebase';

const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
    callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false,
    },
};

export default function FirebaseAuthComponent() {
    const { currentUser } = useAuth();

    return (
        <>
            {!currentUser ? (
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
            ) : (
                <Redirect to='/summary' />
            )}
        </>
    );
}
