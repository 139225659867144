import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore';

export const sellerIds = {
    'Amazon': 'ATVPDKIKX0DER',
    'Max': 'A3B3XKFL4HJA0A',
    'Brian': 'A12115QSRA5J4Q',
}

export function getToken() {
    return firebase.auth().currentUser.getIdToken(true);
}