import { Button, Space, Spin, Switch, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getClassification, getOrdersSingleItem } from "../../services/DataService";
import OrdersTab from "./modal_components/OrdersTab";
import PricingTab from "./modal_components/PricingTab";
import QuantityTab from "./modal_components/QuantityTab";

const { TabPane } = Tabs;

const ModalDetails = ({ item }) => {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [showWinnersOnly, setShowWinnersOnly] = useState(true)

  const classification = useQuery(item.ASIN + 'classification', () =>
    item ? getClassification(item) : null
  );
  const orders = useQuery(item.ASIN + 'orders', () =>
    item ? getOrdersSingleItem(item.ASIN, 'all') : null
  );

  useEffect(() => {
    if (!classification.isLoading && !orders.isLoading) {
      setData(classification.data)
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [classification, orders])

  const onSwitchChange = (status) => {
    setShowWinnersOnly(status)
  }

  return (
    <Spin spinning={loading}>
      <div className="site-card-wrapper">
        <Tabs defaultActiveKey="1" tabBarExtraContent={
          {
            left:
              <Space>
                <Button style={{ marginRight: '16px' }} href={`https://www.amazon.com/dp/${data.ASIN}/?th=1&psc=1`} target="_blank">Link</Button>
              </Space>,
            right: 
              <Space>
                <Switch defaultChecked checkedChildren="Winners only" unCheckedChildren="All sellers" onChange={onSwitchChange} />
              </Space>
          }
        }>
          <TabPane tab="Pricing" key="1">
            <PricingTab data={data} showWinnersOnly={showWinnersOnly}></PricingTab>
          </TabPane>
          <TabPane tab="Quantity" key="2">
            <QuantityTab item={data}></QuantityTab>
          </TabPane>
          <TabPane tab={`Orders - ${orders.data && Object.values(orders.data).some(value => value !== null) ? 'Found' : 'Not found'}`} key="3">
            <OrdersTab orders={orders}></OrdersTab>
          </TabPane>
        </Tabs>
      </div>
    </Spin>
  );
};

export default ModalDetails;
