import DataTable from "../components/data/DataTable";
import { useQuery } from "react-query";
import { getBuySheetData, getDailySummaries, getInventory, getOrdersAll, getOrdersPredictions, getSellerSnap } from "../services/DataService";
import { useEffect, useState } from "react";
import * as dataForge from "data-forge";

const Summary = () => {
    const [data, setData] = useState([])
    const [isLoading, setLoading] = useState(true)

    const summaryData = useQuery('dailysummaries', () =>
        getDailySummaries()
    );

    const sellerSnapData = useQuery('sellersnap', () =>
        getSellerSnap(true)
    );

    const sellerSnapWeeklyData = useQuery('sellersnap_weekly', () =>
        getSellerSnap(false)
    );

    const buySheetData = useQuery('buysheet', async () => {
        return await getBuySheetData();
    })

    const orderData = useQuery('orders', async () => {
        return await getOrdersAll('all');
    })

    const inventoryData = useQuery('inventory', async () => {
        return await getInventory('current');
    })

    const predictionsData = useQuery('predictions', async () =>
        getOrdersPredictions()
    )

    const prepareOrderMap = (orderData) => {
        const orderMap = {};

        orderData.forEach((order) => {
            if (orderMap[order.ASIN]) {
                if (order.AverageDailySales > orderMap[order.ASIN].AverageDailySales) {
                    orderMap[order.ASIN] = order;
                }
            } else {
                orderMap[order.ASIN] = order;
            }
        });

        return orderMap;
    }

    const prepareInventoryMap = (inventoryData) => {
        const invDataFrame = new dataForge.DataFrame(inventoryData)
            .renameSeries({ 'Quantity': 'TotalQuant', 'FulfillableQuantity': 'InstockQuant' })
            .parseInts('TotalQuant', 'InstockQuant', 'InboundWorkingQuantity', 'InboundShippedQuantity', 'InboundReceivingQuantity', 'TotalQuantity')
            .select((row) => {
                const newRow = { ...row };
                newRow['InboundQuant'] = parseInt(row['InboundWorkingQuantity']) + parseInt(row['InboundShippedQuantity']) + parseInt(row['InboundReceivingQuantity'])
                newRow['InstockQuant'] = parseInt(row['InstockQuant']);
                newRow['TotalQuant'] = newRow['InboundQuant'] + newRow['InstockQuant']
                return newRow;
            })
            .pivot('ASIN', {
                'InboundQuant': {
                    InboundQuant: (series) => series.sum(),
                },
                'InstockQuant': {
                    InstockQuant: (series) => series.sum(),
                },
                'TotalQuant': {
                    TotalQuant: (series) => series.sum(),
                },
            })
            .withIndex((row) => row['ASIN'])
            .bake();

        return invDataFrame;
    }

    useEffect(() => {
        if (!summaryData.isLoading && !sellerSnapData.isLoading && !predictionsData.isLoading && !inventoryData.isLoading && !sellerSnapWeeklyData.isLoading) {
            const localData = []
            if (summaryData.isError || sellerSnapData.isError || buySheetData.isError || orderData.isError || predictionsData.isError || sellerSnapWeeklyData.isError) {
                console.log('Failed to grab data - possible authorization error')
            } else {
                console.log('Preparing maps');
                const orderMapMonthly = prepareOrderMap(orderData.data.monthly);
                const orderMapWeekly = prepareOrderMap(orderData.data.weekly);
                const orderMapTriday = prepareOrderMap(orderData.data.triday);
                const orderMapDaily = prepareOrderMap(orderData.data.daily);
                const inventoryFrame = prepareInventoryMap(inventoryData.data);
                console.log('Done with preparing maps');
                
                summaryData.data?.forEach(item => {
                    const ssData = sellerSnapData.data[item.ASIN]
                    const ssWeeklyData = sellerSnapWeeklyData.data[item.ASIN]

                    const ordersItemData = {
                        'daily': orderMapDaily[item.ASIN]?.TotalOrdersQuantity,
                        'triday': orderMapTriday[item.ASIN]?.TotalOrdersQuantity,
                        'weekly': orderMapWeekly[item.ASIN]?.TotalOrdersQuantity,
                        'monthly': orderMapMonthly[item.ASIN]?.TotalOrdersQuantity,
                    }

                    const buySheetItemData = buySheetData.data[item.ASIN];
                    const predictionData = predictionsData.data[item.ASIN];
                    const inventoryItemData = inventoryFrame.at(item.ASIN);

                    localData.push({
                        ...item,
                        "30DSales": !ordersItemData.monthly ? -1 : parseFloat(ordersItemData.monthly),
                        "7DSales": !ordersItemData.weekly ? -1 : parseFloat(ordersItemData.weekly),
                        "3DSales": !ordersItemData.triday ? -1 : parseFloat(ordersItemData.triday),
                        "1DSales": !ordersItemData.daily ? -1 : parseFloat(ordersItemData.daily),
                        "AvgSales": !orderMapMonthly[item.ASIN] ? -1 : parseFloat(orderMapMonthly[item.ASIN].AverageDailySales),
                        'ProjSales': !predictionData ? -1 : predictionData.PredictedDailySales,
                        ...buySheetItemData,
                        ...inventoryItemData,
                        RemDays: inventoryItemData && orderMapMonthly[item.ASIN] ? parseFloat((inventoryItemData.TotalQuant / parseFloat(orderMapMonthly[item.ASIN].AverageDailySales)).toFixed(2)) : -1, 
                        SellerSnapExists: ssData ? "Yes" : "No",
                        InStock: ssData ? ssData.days_in_stock : -1,
                        MaxPrice: ssData ? ssData.max_price : -1,
                        MinPrice: ssData ? ssData.min_price : -1,
                        Quantity: ssData ? ssData.fulfillable_quantity : -1,
                        InvValue: ssData ? ssData.inv_value : -1,
                        Tags: ssData ? ssData.tags : [],
                        SellersnapDailyShare: ssData?.buybox_share ?? -1,
                        SellersnapBBShareMonthly: ssData?.avg_buybox_share ?? -1,
                        SellersnapBBShareWeekly: ssWeeklyData?.avg_buybox_share ?? -1,
                        SellerSnapData: ssData,
                    })
                })
                console.log('Done with data transforms');
            }
    
            setData(localData)
            setLoading(false)
        }
    }, [summaryData, sellerSnapData, buySheetData, orderData, inventoryData, predictionsData, sellerSnapWeeklyData])

    return (
    <>
        <DataTable loading={isLoading} data={data} />
    </>
    );
}

export default Summary;
