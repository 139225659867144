const dataForge = require('data-forge')

/**
 *
 * @param {dataForge.DataFrame} data
 * @return {dataForge.DataFrame}
 */
 export function PivotByParent(data) {
    const pivoted = (new dataForge.DataFrame(data)).pivot('ParentASIN', {
        'Title': {
            Title: (series) => series.first(),
        },
        'ParentASIN': {
            ParentASIN: (series) => series.first(),
        },
        'AvgSellPrice': {
            AvgSellPrice: (series) => parseFloat(series.where((val) => val > 0).average().toFixed(2)),
        },
        'DailySalesEst': {
            DailySalesEst: (series) => parseFloat(series.sum().toFixed(2)),
        },
        'Variations': {
            ASIN: (series) => series.count(),
        },
    });

    return pivoted.toArray();
}