import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import './App.less';
import Header from './components/layout/Header';
import Details from './routes/Details';
import Summary from './routes/Summary';
import firebase from './utility/firebase';
import { getSellerNames, getTitles } from './services/DataService';
import SellerContext from './services/SellerContext';
import Pecty from './routes/Pecty';
import { AuthProvider } from './services/AuthContext';
import PrivateRoute from './routes/PrivateRoute';
import FirebaseAuthComponent from './components/layout/FirebaseAuthComponent';
import DataContext from './services/DataContext';
import Insights from './routes/Insights';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});
const { Content } = Layout;

function App() {
    const [sellerMap, setSellerMap] = useState({});
    const [dataRepo, setDataRepo] = useState({});

    useEffect(() => {
        getSellerNames().then(data => setSellerMap(data));
        console.log('Updated seller map');
    }, []);

    useEffect(() => {
        getTitles().then(data => setDataRepo(dataRepo => ({ ...dataRepo, titles: data })));
        console.log('Updated title map');
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <SellerContext.Provider value={sellerMap}>
                <DataContext.Provider value={dataRepo}>
                    <Router>
                        <AuthProvider>
                            <Header firebaseApp={firebase} />
                            <Layout className='layout'>
                                <Content
                                    style={{
                                        padding: '15px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <div
                                        className='site-layout-content'
                                        style={{
                                            flexBasis: '95%',
                                            maxWidth: '100%',
                                            overflow: 'hidden' 
                                        }}
                                    >
                                        <Switch>
                                            <PrivateRoute exact path='/summary'>
                                                <Summary />
                                            </PrivateRoute>

                                            <PrivateRoute exact path='/reports'>
                                                <Details />
                                            </PrivateRoute>

                                            <PrivateRoute exact path='/pecty'>
                                                <Pecty />
                                            </PrivateRoute>

                                            <PrivateRoute exact path='/insights'>
                                                <Insights />
                                            </PrivateRoute>

                                            <Route
                                                exact
                                                path='/logout'
                                                render={() => {
                                                    firebase.auth().signOut();
                                                    return <Redirect to='/login' />;
                                                }}
                                            />

                                            <Route exact path='/login'>
                                                <FirebaseAuthComponent />
                                            </Route>
                                        </Switch>
                                    </div>
                                </Content>

                                {/* <Footer style={{ textAlign: 'center' }}>
                                    Project: Repricer
                            </Footer> */}
                            </Layout>
                        </AuthProvider>
                    </Router>
                </DataContext.Provider>
            </SellerContext.Provider>
        </QueryClientProvider>
    );
}

export default App;
