import { MinMaxFilterDropdown } from "../MinMaxFilter"

export const getNumberFilterSorter = key => {
    return {
        sorter: (a, b) => {
            const localA = a[key] ? a[key] : -1
            const localB = b[key] ? b[key] : -1
            return localA - localB
        },
        filterDropdown: (props) => (
            <MinMaxFilterDropdown props={{ ...props }} defaultMin={0} defaultMax={0} />
        ),
        onFilter: (value, record) => {
            const [min, max] = value.split(" ").map(val => parseFloat(val));
            return record[key] > min && (max === 0 || record[key] < max);
        },
    }
}

export const getBooleanFilter = key => {
    return {
        filters: [
            {
                text: 'True',
                value: true,
            },
            {
                text: 'False',
                value: false,
            },
        ],
        filterMultiple: false,
        onFilter: (value, record) => record[key] === value
    }
}

export const getPriceRenderer = () => {
    return {
        render: val => {
            return val === undefined || val === -1 || val === null ? '?' : `$${parseFloat(val).toFixed(2)}`
        },
    }
}

export const getShareRenderer = () => {
    return {
        render: val => val === undefined || val === -1 ? 'Unknown' : `${val}%`,
    }
}

export const getNumberRenderer = (float = false) => {
    return {
        render: val => val === undefined || val === null || val === -1 ? '?' : `${float ? val.toFixed(2) : val}`,
    }
}

