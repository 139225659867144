import React, { useState } from "react";
import { Input, Space, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export function MinMaxFilterDropdown({ props, defaultMin, defaultMax}) {
    const [min, setMin] = useState(defaultMin);
    const [max, setMax] = useState(defaultMax);

    return (
        <div style={{ padding: 8 }}>
            <Input
                // ref={props.inputRef}
                placeholder="Min"
                type="number"
                value={min}
                onChange={(e) => {
                    setMin(Number(e.target.value))
                    props.setSelectedKeys([e.target.value + " " + max]);
                }}
                style={{ width: 188, marginBottom: 8, display: "block" }}
                onPressEnter={props.confirm}
            />
            <Input
                // ref={props.inputRef}
                placeholder="Max"
                type="number"
                value={max}
                onChange={(e) => {
                    setMax(Number(e.target.value))
                    props.setSelectedKeys([min + " " + e.target.value]);
                }}
                style={{ width: 188, marginBottom: 8, display: "block" }}
                onPressEnter={props.confirm}
            />
            <Space>
                <Button
                    type="primary"
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                    onClick={() => {
                        props.setSelectedKeys([min + " " + max])
                        props.confirm()
                    }}
                >
                    Search
        </Button>
                <Button onClick={props.clearFilters} style={{ width: 90 }}>
                    Reset
        </Button>
            </Space>
        </div>
    );
}
