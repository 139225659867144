import { Card, Col, Row, Space, Statistic } from "antd";
import { List } from "antd";
import React, { useContext, useEffect, useState } from "react";
import SellerContext from "../../services/SellerContext";

const BuyBoxModal = ({ offer }) => {
    const [sellerData, setSellerData] = useState([]);
    const [priceData, setPriceData] = useState([]);
    const sellerMap = useContext(SellerContext);

    useEffect(() => {
        const localPriceData = [];
        Object.entries(offer).forEach(([seller, priceData]) => {
            localPriceData.push({
                'seller': seller,
                'price': priceData.price,
                'isWinner': priceData.isWinner,
                'type': priceData.fullfillment,
            })
        })
        setSellerData(localPriceData.filter(data => data.isWinner));
        setPriceData(localPriceData);
    }, [offer])

    const styles = {
        card: {
            maxHeight: "100%"
        },
        cardBody: {
            height: 260,
            maxHeight: 260,
            overflow: "auto"
        },
        listSingle: {
            height: 262,
            maxHeight: 262,
            overflow: "auto"
        },
        listDouble: {
            height: 524,
            maxHeight: 524,
            overflow: "auto"
        }
    };

    const renderSellerName = (sellerId) => sellerMap[sellerId] ? sellerMap[sellerId] : sellerId

    return (
        <div className="site-card-wrapper">
            <Row gutter={[4, 8]}>
                {/* First row */}
                <Col span={12}>
                    <Card bordered={true} style={styles.card} bodyStyle={styles.cardBody}>
                        <Space direction="vertical">

                            <Statistic title="BuyBox Owner" value={renderSellerName(sellerData[0]?.seller)} />
                            <Statistic title="BuyBox Price" value={sellerData[0]?.price} />
                            <Statistic title="BuyBox Type" value={sellerData[0]?.type === "Amazon" ? "FBA" : "FBM"} />
                        </Space>
                    </Card>
                    {sellerData[1]
                        ?
                        <Card bordered={true} style={styles.card} bodyStyle={styles.cardBody}>
                            <Space direction="vertical">

                                <Statistic title="BuyBox Owner" value={renderSellerName(sellerData[1]?.seller)} />
                                <Statistic title="BuyBox Price" value={sellerData[1]?.price} />
                                <Statistic title="BuyBox Type" value={sellerData[1]?.type === "Amazon" ? "FBA" : "FBM"} />
                            </Space>
                        </Card>
                        :
                        null
                    }

                </Col>
                <Col span={12}>
                    {/* <Card bordered={true} style={styles.card} bodyStyle={sellerData[1] ? styles.cardBodyList : styles.cardBody}> */}
                        <List
                            size="small"
                            bordered
                            dataSource={priceData}
                            style={sellerData[1] ? styles.listDouble : styles.listSingle}
                            renderItem={item => <List.Item>{renderSellerName(item.seller)} : ${item.price}</List.Item>}
                        />
                    {/* </Card> */}
                </Col>
            </Row>
        </div>
    );
};

export default BuyBoxModal;
