import { UploadOutlined } from "@ant-design/icons";
import { Form, Button, Input, Upload, Spin, Modal } from "antd";
import * as dataForge from "data-forge";
import React from "react"
import { requestMultiplePectyData } from "../../../services/DataService";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};

const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

const InsightsImport = () => {
    const [form] = Form.useForm();
    const [fileList, setFileList] = React.useState([])
    const [uploading, setUploading] = React.useState(false)

    const beforeUpload = (file) => {
        const newFileList = [...fileList, file]
        setFileList(newFileList)
        return false;
    }

    const extractASINsFromFile = (fileText) => {
        if (fileText.length > 0) {
            const fileFrame = dataForge.fromCSV(fileText);
            const asinCol = fileFrame.getColumnNames().find(col => col.toLowerCase().includes("asin"))
            return fileFrame.getSeries(asinCol).toArray()
        } else {
            return []
        }
    }

    const processData = (fileText = '') => {
        const collection = form.getFieldValue("collection")
        const asinsFromClipboard = form.getFieldValue("asinListClipboard")?.split('\n') || []
        const asinsFromFile = extractASINsFromFile(fileText)
        const asinsToRequest = [...asinsFromClipboard, ...asinsFromFile]

        requestMultiplePectyData(asinsToRequest, `insights_${collection}`)
            .then(res => {
                if (res.error === null) {
                    Modal.success({
                        title: `Success!`,
                        content:    `Started requesting data for ${asinsToRequest.length} from Pecty! 
                                    Please check back later.`,
                    });
                } else {
                    Modal.error({
                        title: `${res.msg}`,
                        content: `${res.error}`,
                    });
                }
            }).catch((ex) => Modal.error({
                title: `${ex.message}`,
                content: `${ex}`,
            })).finally(() => {
                setUploading(false)
            })
    }

    const onFinish = () => {
        setUploading(true)

        const reader = new FileReader()
        reader.onload = async (e) => { 
            const text = (e.target.result)
            processData(text)
        };

        if (fileList.length > 0) {
            reader.readAsText(fileList[0])
        } else {
            processData()
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onRemove = (file) => {
        const newFileList = fileList.filter(el => el.uid !== file.uid)
        setFileList(newFileList)
    }

    return (
        <Spin spinning={uploading}>
            <Form
                {...layout}
                form={form}
                name="brandForm"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label="Collection"
                    name="collection"
                    rules={[{ required: true, message: 'Please input collection name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="asinListFile"
                    label="ASIN list (file)"
                    valuePropName="asinListFileData"
                    extra={<p><b>Required columns:</b><br/>ASIN</p>}
                >
                    <Upload beforeUpload={beforeUpload} onRemove={onRemove} accept={'.tsv,.csv'} name="asinListFile" listType="text">
                        <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </Upload>
                </Form.Item>
                <Form.Item 
                    name="asinListClipboard" 
                    label="ASIN list (clipboard)"
                    extra={<p>Each line should containt just one ASIN</p>}
                >
                    <Input.TextArea />
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Button style={{
                    backgroundColor: "#ED7339",
                    borderColor: "#ED7339",
                    color: "#fff",
                    }} type="primary" htmlType="submit">
                        Submit
                </Button>
                </Form.Item>
            </Form>
        </Spin>
    )
}

export default InsightsImport