import { Table } from "antd";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import React, { useMemo } from "react";
import { getNumberFilterSorter, getNumberRenderer, getPriceRenderer } from "../../utilities/TableFilterSorters";
import { CheckOutlined, WarningFilled, CrownOutlined } from "@ant-design/icons";
import { MaxSellerName } from "../../../../utility/constants";
dayjs.extend(duration)
dayjs.extend(relativeTime)

const QuantityTable = ({ insight }) => {

    const tableData = useMemo(() => {
        return insight.OfferData?.map(offer => ({ ...offer, IsBuyBoxWinner: offer.sellerId === insight.buyboxSellerId }))
    }, [insight])

    console.log(tableData)

    const columns = useMemo(() => ([
        {
            title: 'Seller',
            dataIndex: 'sellerName',
            key: 'sellerName',
            width: "150px",
            render: (value, record) =>
                record.inventoryCount > record.BandBaseSales ? (
                    <>{value} <CrownOutlined style={{color: 'yellow'}}  /></>
                ) : value
        },
        {
            title: 'Type',
            dataIndex: 'fba',
            key: 'fba',
            width: "50px",
            render: (val) => val ? "FBA" : "FBM"
        },
        {
            title: 'Price',
            dataIndex: 'priceTotal',
            key: 'priceTotal',
            width: "100px",
            ...getNumberFilterSorter('priceTotal'),
            ...getPriceRenderer(),
        },
        {
            title: 'Quantity',
            dataIndex: 'inventoryCount',
            key: 'inventoryCount',
            width: "100px",
            ...getNumberFilterSorter('inventoryCount')
        },
        {
            title: 'MOQ',
            dataIndex: 'moq',
            key: 'moq',
            width: "40px",
            render: (value) =>
                value ? (
                    <WarningFilled style={{color: 'red', width: '100%'}} />
                ) : null
        },
        {
            title: 'GMROI',
            dataIndex: 'GMROI',
            key: 'GMROI',
            width: "100px",
            ...getNumberFilterSorter('GMROI'),
            render: (val, record) => {
                return val === undefined || val === -1 || val === null
                    ? "Unknown"
                    : `${parseFloat(val).toFixed(2)}`;
            },
        },
        {
            title: 'Profit',
            dataIndex: 'Profit',
            key: 'Profit',
            width: "100px",
            ...getNumberFilterSorter('Profit'),
            ...getPriceRenderer(),
        },
        {
            title: 'Total Profit',
            dataIndex: 'TotalProfit',
            key: 'TotalProfit',
            width: "100px",
            ...getNumberFilterSorter('TotalProfit'),
            ...getPriceRenderer(),
        },
        {
            title: 'Your TTS',
            dataIndex: 'YourBandTTS',
            key: 'YourBandTTS',
            width: "100px",
            ...getNumberFilterSorter('YourBandTTS'),
            ...getNumberRenderer()
        },
        {
            title: 'Band',
            dataIndex: 'BandIndex',
            key: 'BandIndex',
            width: "100px",
            ...getNumberFilterSorter('BandIndex'),
            ...getNumberRenderer()
        },
        {
            title: 'Price +%',
            dataIndex: 'PriceChange',
            key: 'PriceChange',
            width: "80px",
            ...getNumberFilterSorter('PriceChange'),
            ...getNumberRenderer(true)
        },
        {
            title: 'Velocity +%',
            dataIndex: 'VelocityChange',
            key: 'VelocityChange',
            width: "80px",
            ...getNumberFilterSorter('VelocityChange'),
            ...getNumberRenderer(true)
        },
        {
            title: 'BandSales',
            dataIndex: 'BandBaseSales',
            key: 'BandBaseSales',
            width: "100px",
            ...getNumberFilterSorter('BandBaseSales'),
            ...getNumberRenderer(true)
        },
        {
            title: 'Wait Period',
            dataIndex: 'BandWaitTime',
            key: 'BandWaitTime',
            width: "100px",
            ...getNumberFilterSorter('BandWaitTime'),
            ...getNumberRenderer()
        },
        {
            title: 'Wait Benefit',
            dataIndex: 'WaitBenefit',
            key: 'WaitBenefit',
            width: "100px",
            ...getNumberFilterSorter('WaitBenefit'),
            ...getNumberRenderer(true)
        },
        {
            title: 'Prime',
            dataIndex: 'prime',
            key: 'prime',
            width: "40px",
            render: (value) =>
                value ? (
                    <CheckOutlined style={{ width: "100%" }} />
                ) : null
        },
    ]), [])

    return (
        <Table
            rowKey={row => row.sellerName}
            rowClassName={(record, index) => {
                let classNames = []
                
                if (record.sellerName === MaxSellerName) {
                    classNames.push("keepa-estsales")
                }

                return classNames.join(" ")
            }}
            bordered={true}
            columns={columns}
            scroll={{ y: "68vh" }}
            sticky
            size="small"
            tableLayout={"fixed"}
            dataSource={tableData}
        />
    );
};

export default QuantityTable;
