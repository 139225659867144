import { Card, Col, Row, Space, Statistic } from "antd";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import React from "react";
dayjs.extend(duration)
dayjs.extend(relativeTime)

const OrdersTab = ({ orders }) => {
    const styles = {
        card: {
            maxHeight: "100%"
        },
        cardBody: {
            height: 260,
            maxHeight: 260,
            overflow: "auto"
        },
        ordersCardBody: {
            height: 325,
            maxHeight: 325,
            overflow: "auto"
        },
    };

    return (
        <Row gutter={[8, 8]}>
            {/* First row */}
            <Col span={6}>
                <Card title="Monthly" bordered={true} style={styles.card} bodyStyle={styles.ordersCardBody}>
                    <Space direction="vertical">
                        <Statistic title="Quantity" value={orders.data?.monthly?.TotalOrdersQuantity} />
                        <Statistic title="Average Daily" value={orders.data?.monthly?.AverageDailySales} />
                        <Statistic title="Average Price" value={orders.data?.monthly?.AverageItemPrice} />
                        <Statistic title="Total Value" value={orders.data?.monthly?.TotalOrdersValue} />
                    </Space>
                </Card>
            </Col>
            <Col span={6}>
                <Card title="Weekly" bordered={true} style={styles.card} bodyStyle={styles.ordersCardBody}>
                    <Space direction="vertical">
                        <Statistic title="Quantity" value={orders.data?.weekly?.TotalOrdersQuantity} />
                        <Statistic title="Average Daily" value={orders.data?.weekly?.AverageDailySales} />
                        <Statistic title="Average Price" value={orders.data?.weekly?.AverageItemPrice} />
                        <Statistic title="Total Value" value={orders.data?.weekly?.TotalOrdersValue} />
                    </Space>
                </Card>
            </Col>
            <Col span={6}>
                <Card title="Triday" bordered={true} style={styles.card} bodyStyle={styles.ordersCardBody}>
                    <Space direction="vertical">
                        <Statistic title="Quantity" value={orders.data?.triday?.TotalOrdersQuantity} />
                        <Statistic title="Average Daily" value={orders.data?.triday?.AverageDailySales} />
                        <Statistic title="Average Price" value={orders.data?.triday?.AverageItemPrice} />
                        <Statistic title="Total Value" value={orders.data?.triday?.TotalOrdersValue} />
                    </Space>
                </Card>
            </Col>
            <Col span={6}>
                <Card title="Daily" bordered={true} style={styles.card} bodyStyle={styles.ordersCardBody}>
                    <Space direction="vertical">
                        <Statistic title="Quantity" value={orders.data?.daily?.TotalOrdersQuantity} />
                        <Statistic title="Average Daily" value={orders.data?.daily?.AverageDailySales} />
                        <Statistic title="Average Price" value={orders.data?.daily?.AverageItemPrice} />
                        <Statistic title="Total Value" value={orders.data?.daily?.TotalOrdersValue} />
                    </Space>
                </Card>
            </Col>
        </Row>
    );
};

export default OrdersTab;
