import { Divider, Space } from "antd";
import { useContext, useMemo, useState } from "react";
import { useQuery } from "react-query";
import ASINDropdown from "../components/data/ASINDropdown";
import CollectionDropdown from "../components/data/modal_components/quantity/CollectionDropdown";
import PectyTable from "../components/data/modal_components/quantity/PectyTable";
import { getPectyASINs, getPectyCollections, getPectyData, getPectyPredictions } from "../services/DataService";
import { CSVDownload } from "../components/data/CSVDownload";
import DataContext from "../services/DataContext";
import { PivotByParent } from "../utility/helpers";

const Pecty = () => {
    const [collection, setCollection] = useState()
    const [pectyASIN, setPectyASIN] = useState("")
    const dataRepo = useContext(DataContext)

    const { data, isLoading } = useQuery(['pecty', pectyASIN], () =>
        pectyASIN ? getPectyData(pectyASIN, collection).then(data => data.map(row => ({ ...row.data, ...row }))) : null
    );

    const asinQuery = () => getPectyASINs(collection)

    const predictionData = useQuery(['pecty', 'predictions', collection], () =>
        collection ? getPectyPredictions(collection).then(data => {
            data.forEach(item => {
                if (dataRepo.titles[item.ASIN]) {
                    item.Title = dataRepo.titles[item.ASIN]
                }
            })

            return data
        }) : null
    );

    const predictionPivotedData = useMemo(() => {
        if (predictionData.data && predictionData.data.filter(prod => prod.ParentASIN).length > 0) {
            return PivotByParent(predictionData.data)
        } else {
            return null
        }
    }, [predictionData.data])

    console.log(predictionPivotedData)

    return (
        <>
            <Space direction="vertical" style={{ display: "flex", alignContent: "center" }}>
                <div style={{ display: "flex" }}>
                    <CollectionDropdown queryKey={'pectyCollections'} query={getPectyCollections} onChange={(x) => setCollection(x)} />
                </div>
                <CSVDownload title="pecty_prediction" collection={collection} data={predictionData.data} block style={{ alignSelf: "center" }}>Download Predictions</CSVDownload>
                <CSVDownload title="pecty_prediction" collection={`${collection}_pivoted`} data={predictionPivotedData} block style={{ alignSelf: "center" }}>Download Pivoted predictions (requires variation data!)</CSVDownload>
            </Space>
            <Divider />
            <div style={{ display: "flex" }}>
                <ASINDropdown queryKey={collection} query={asinQuery} onChange={(x) => setPectyASIN(x)} />
            </div>
            <Divider />
            <PectyTable loading={isLoading} data={data} showTitle={true} />
        </>
    );
}

export default Pecty;
