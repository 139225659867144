import { Button, message, Space, Spin, Tabs } from "antd";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import React from "react";
import { useQuery, useQueryClient } from "react-query";
import { getPectyData, requestPectyData } from "../../../services/DataService";
import { PectyCollections } from "../../../utility/constants";
import PectyTable from "./quantity/PectyTable";
const { TabPane } = Tabs;
dayjs.extend(duration)
dayjs.extend(relativeTime)

const QuantityTab = ({ item }) => {
    const { data, isLoading, isFetching } = useQuery(['pecty', PectyCollections.Summary, item?.ASIN], () =>
        item.ASIN ? getPectyData(item.ASIN, PectyCollections.Summary).then(data => data.map(row => ({ ...row.data, ...row }))) : null
    );
    const queryClient = useQueryClient()

    return (
        <Tabs defaultActiveKey="1" tabBarExtraContent={
            {
              right: 
                <Space>
                  <Button type="link" style={{ marginRight: '16px' }} onClick={async () => queryClient.invalidateQueries(['pecty', PectyCollections.Summary, item?.ASIN]).then(res => message.success('Data refreshed'))}>Refresh</Button>
                  <Button type="link" style={{ marginRight: '16px' }} onClick={async () => requestPectyData(item.ASIN, PectyCollections.Summary).then(res => res.error === null ? message.success('Data requested') : message.error(res.error))}>Request</Button>
                </Space>
            }
          }>
            <TabPane tab="Table" key="1">
                <Spin spinning={isFetching}>
                    <PectyTable loading={isLoading && isFetching} data={data} />
                </Spin>
            </TabPane>
            <TabPane tab="Chart - WIP" key="2">
                <Spin spinning={isFetching}>
                    <PectyTable loading={isLoading && isFetching} data={data} />
                </Spin>
            </TabPane>
        </Tabs>
    );
};

export default QuantityTab;
