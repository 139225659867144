import { Button, Col, Form, InputNumber, message, Modal, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { pushSellerSnap } from "../../../../services/DataService";

const InsightEditor = ({ insight }) => {
    const [isLoading, setLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            min_price: parseFloat(insight.SSData.min_price),
            max_price: parseFloat(insight.SSData.max_price),
        });
    }, [insight, form])

    const onFinish = (values) => {
        setLoading(true);
        const updateData = [{
            sku: insight.SSData.sku,
            ...values
        }]

        console.log('updateData', updateData)
        pushSellerSnap(updateData).then(res => {
            if (res[0].data.errors === 0) {
                message.success('Success!');
            } else {
                Modal.error({
                    title: `Error!`,
                    content: `${res[0].data.errors}`,
                });
            }
        }).finally(() => setLoading(false))
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Row gutter={[8, 8]} align="middle" style={{ marginBottom: '16px', justifyContent: 'center', display: 'flex' }}>
            <Col span={8}>
                <Spin spinning={isLoading}>
                    <Form
                        form={form}
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 8 }}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Min Price"
                            name="min_price"
                            rules={[{ required: true, type: "number", message: 'Please input your Min Price (must be a valid number)!' }]}
                        >
                            <InputNumber min={1} precision={2} />
                            {/* <InputNumber min={1} precision={2} defaultValue={insight.SSData.min_price}/> */}
                        </Form.Item>

                        <Form.Item
                            label="Max Price"
                            name="max_price"
                            rules={[{ required: true, type: "number", message: 'Please input your Max Price (must be a valid number)!' }]}
                        >
                            <InputNumber min={1} precision={2} />
                            {/* <InputNumber min={1} precision={2} defaultValue={insight.SSData.max_price}/> */}
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>

            </Col>
        </Row>
    );
};

export default InsightEditor;
