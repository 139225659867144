import { Button, Divider, message, Space } from "antd";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import InsightsTable from "./InsightsTable";
import CollectionDropdown from "../modal_components/quantity/CollectionDropdown";
import { getPectyInsights, getPectyCollections } from "../../../services/DataService";

const InsightsView = () => {
    const [collection, setCollection] = useState()
    const queryClient = useQueryClient()

    const predictionData = useQuery(['pecty', 'insights', collection], () =>
        collection ? getPectyInsights(collection) : []
    );

    console.log(predictionData)

    return (
        <>
            <Space direction="vertical" style={{ display: "flex", alignContent: "center" }}>
                <div style={{ display: "flex" }}>
                    <CollectionDropdown
                        queryKey={'pectyCollections'}
                        query={() => getPectyCollections()
                            .then(list => list.filter(col => col.includes("insights")))
                        }
                        onChange={(x) => setCollection(x)}
                    />
                    <Button type="primary" onClick={() => queryClient.invalidateQueries('pectyCollections').then(_ => message.success('Collections refreshed'))}>Refresh</Button>
                </div>
            </Space>
            <Divider />
            <InsightsTable 
                isLoading={predictionData.isLoading} 
                data={predictionData.data?.result?.filter(item => item.Cost !== null)} 
                showTitle={true} />
        </>
    );
}

export default InsightsView;
