import React from "react";
import { Link, Switch, Route } from "react-router-dom";
import { Button } from "antd";

const NavButton = (props) => (
  <Link to={props.url}>
    <Switch>
      <Route path={props.url} exact>
        <Button type="primary" onClick={props.onClick}>{props.name}</Button>
      </Route>
      <Route>
        <Button>{props.name}</Button>
      </Route>
    </Switch>

  </Link>
);

export default NavButton;
