import React from "react";
import { Select } from "antd";
import { useQuery } from "react-query";

const CollectionDropdown = ({ onChange, query, queryKey }) => {
  const { data } = useQuery(queryKey, query);
  
  return (
    <Select 
      defaultValue="Choose collection..." 
      onChange={onChange} 
      showSearch
      filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      style={{width:"100%"}}
      >
      {data?.sort().map((collection, i) => (
        <Select.Option value={collection} key={i} >
          {collection}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CollectionDropdown;
