import { ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Space, Switch, Table, Tag, Typography } from "antd";
import Text from 'antd/lib/typography/Text';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ModalDetails from "./ModalDetails";
import { CSVDownload } from './CSVDownload';
import { getBooleanFilter, getNumberFilterSorter, getNumberRenderer, getPriceRenderer, getShareRenderer } from './utilities/TableFilterSorters';
const { Link } = Typography;

const DataTable = ({ data, loading }) => {
    const [visible, setVisibility] = useState(false);
    const [csvData, setCSVData] = useState([]);
    const [item, setItem] = useState(null);
    const [showNameColumn, setShowNameColumn] = useState(true);
    const [showRepricerColumns, setShowRepricerColumns] = useState(true);
    const [showOrdersColumns, setShowOrdersColumns] = useState(true);
    const [showSellerSnapColumns, setShowSellerSnapColumns] = useState(true);
    const [showBuySheetColumns, setShowBuySheetColumns] = useState(true);
    const [showInventoryColumns, setShowInventoryColumns] = useState(true);
    const searchRef = useRef();

    useEffect(() => {
        prepareCSVData(data)
    }, [data])

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };

    const handleReset = clearFilters => {
        clearFilters();
    };

    const getColumnSearchProps = useCallback(dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchRef}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
              </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
              </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchRef.current?.select(), 100);
            }
        },
        render: text => dataIndex === 'ASIN' ?
            (<Link href={`https://www.amazon.com/dp/${text}/?th=1&psc=1`} target="_blank">
                {text}
            </Link>)
            :
            (text)
    }), []);

    const columns = useMemo(() => ([
        {
            title: 'ASIN',
            dataIndex: 'ASIN',
            key: 'ASIN',
            width: "125px",
            fixed: "left",
            render: ASIN => (
                <Link href={`https://www.amazon.com/dp/${ASIN}/?th=1&psc=1`} target="_blank">
                    {ASIN}
                </Link>
            ),
            ...getColumnSearchProps('ASIN'),
        },
    ]), [getColumnSearchProps])

    const nameColumn = useMemo(() => ([
        {
            title: 'Name',
            dataIndex: 'Name',
            key: 'Name',
            width: "275px",
            ...getColumnSearchProps('Name'),
        },
    ]), [getColumnSearchProps])

    const repricerColumns = useMemo(() => ([
        {
            title: 'Repricer',
            children: [
                {
                    title: 'BuyBox',
                    dataIndex: 'CurrentBB',
                    key: 'CurrentBB',
                    width: "100px",
                    ...getNumberFilterSorter('CurrentBB'),
                    ...getPriceRenderer()
                },
                {
                    title: 'Yours',
                    dataIndex: 'CurrentYour',
                    key: 'CurrentYour',
                    width: "90px",
                    ...getNumberFilterSorter('CurrentYour'),
                    ...getPriceRenderer()
                },
                {
                    title: 'BO',
                    dataIndex: 'CurrentlyBackordered',
                    key: 'CurrentlyBackordered',
                    width: "90px",
                    ...getBooleanFilter('CurrentlyBackordered'),
                    render: (value) => {
                        return <Text>{value ? "True" : "False"}</Text>
                    }
                },
                {
                    title: 'FBA',
                    dataIndex: 'FBACompetitors',
                    width: "85px",
                    key: 'FBACompetitors',
                    ...getNumberFilterSorter('FBACompetitors')
                },
                {
                    title: 'Share',
                    dataIndex: 'BBShare',
                    key: 'BBShare',
                    width: "90px",
                    ...getNumberFilterSorter('BBShare'),
                    ...getShareRenderer(),
                },
                {
                    title: 'Weighted',
                    dataIndex: 'WeightedBBShare',
                    key: 'WeightedBBShare',
                    width: "120px",
                    ...getNumberFilterSorter('WeightedBBShare'),
                    ...getShareRenderer(),
                },
                {
                    title: 'Suppressed',
                    dataIndex: 'SuppressedShare',
                    key: 'SuppressedShare',
                    width: "125px",
                    ...getNumberFilterSorter('SuppressedShare'),
                    ...getShareRenderer(),
                },
            ]
        },
    ]), [])

    const ordersColumns = useMemo(() => {
        return [
            {
                title: 'Orders',
                children: [
                    {
                        title: '1D',
                        dataIndex: '1DSales',
                        key: '1DSales',
                        width: "80px",
                        ...getNumberFilterSorter('1DSales'),
                        ...getNumberRenderer()
                    },
                    {
                        title: '3D',
                        dataIndex: '3DSales',
                        key: '3DSales',
                        width: "80px",
                        ...getNumberFilterSorter('3DSales'),
                        ...getNumberRenderer()
                    },
                    {
                        title: '7D',
                        dataIndex: '7DSales',
                        key: '7DSales',
                        width: "80px",
                        ...getNumberFilterSorter('7DSales'),
                        ...getNumberRenderer()
                    },
                    {
                        title: '30D',
                        dataIndex: '30DSales',
                        key: '30DSales',
                        width: "80px",
                        ...getNumberFilterSorter('30DSales'),
                        ...getNumberRenderer()
                    },
                    {
                        title: 'Proj',
                        dataIndex: 'ProjSales',
                        key: 'ProjSales',
                        width: "80px",
                        ...getNumberFilterSorter('ProjSales'),
                        ...getNumberRenderer()
                    },
                ]
            },
        ]
    }, [])

    const inventoryColumns = useMemo(() => {
        return [
            {
                title: 'Inventory',
                children: [
                    {
                        title: 'Inbound',
                        dataIndex: 'InboundQuant',
                        key: 'InboundQuant',
                        width: "100px",
                        ...getNumberFilterSorter('InboundQuant'),
                        ...getNumberRenderer()
                    },
                    {
                        title: 'Instock',
                        dataIndex: 'InstockQuant',
                        key: 'InstockQuant',
                        width: "100px",
                        ...getNumberFilterSorter('InstockQuant'),
                        ...getNumberRenderer()
                    },
                    {
                        title: 'Total',
                        dataIndex: 'TotalQuant',
                        key: 'TotalQuant',
                        width: "100px",
                        ...getNumberFilterSorter('TotalQuant'),
                        ...getNumberRenderer()
                    },
                    {
                        title: 'RemDays',
                        dataIndex: 'RemDays',
                        key: 'RemDays',
                        width: "110px",
                        ...getNumberFilterSorter('RemDays'),
                        ...getNumberRenderer()
                    },
                ]
            },
        ]
    }, [])

    const sellerSnapColumns = useMemo(() => {
        return [
            {
                title: 'SellerSnap',
                children: [
                    {
                        title: 'Min',
                        dataIndex: 'MinPrice',
                        key: 'MinPrice',
                        width: "85px",
                        ...getNumberFilterSorter('MinPrice'),
                        ...getPriceRenderer()
                    },
                    {
                        title: 'Max',
                        dataIndex: 'MaxPrice',
                        key: 'MaxPrice',
                        width: "85px",
                        ...getNumberFilterSorter('MaxPrice'),
                        ...getPriceRenderer()
                    },
                    {
                        title: 'Quantity',
                        dataIndex: 'Quantity',
                        key: 'Quantity',
                        width: "110px",
                        ...getNumberFilterSorter('Quantity'),
                        ...getNumberRenderer()
                    },
                    {
                        title: 'InStock',
                        dataIndex: 'InStock',
                        key: 'InStock',
                        width: "110px",
                        ...getNumberFilterSorter('InStock'),
                        ...getNumberRenderer()
                    },
                    {
                        title: 'InvValue',
                        dataIndex: 'InvValue',
                        key: 'InvValue',
                        width: '105px',
                        ...getNumberFilterSorter('InvValue'),
                        ...getPriceRenderer()
                    },
                    {
                        title: 'DailyShare',
                        dataIndex: 'SellersnapDailyShare',
                        key: 'SellersnapDailyShare',
                        width: '140px',
                        ...getNumberFilterSorter('SellersnapDailyShare'),
                        ...getShareRenderer()
                    },
                    {
                        title: 'WeeklyShare',
                        dataIndex: 'SellersnapBBShareWeekly',
                        key: 'SellersnapBBShareWeekly',
                        width: '140px',
                        ...getNumberFilterSorter('SellersnapBBShareWeekly'),
                        ...getShareRenderer()
                    },
                    {
                        title: 'MonthlyShare',
                        dataIndex: 'SellersnapBBShareMonthly',
                        key: 'SellersnapBBShareMonthly',
                        width: '150px',
                        ...getNumberFilterSorter('SellersnapBBShareMonthly'),
                        ...getShareRenderer()
                    },
                    {
                        title: 'Tags',
                        dataIndex: 'Tags',
                        key: 'Tags',
                        width: '95px',
                        ...getColumnSearchProps('Tags'),
                        render: (tags) => (
                            <Space direction="vertical" align="center" style={{ width: "100%" }}>
                                {tags.map(tag => (
                                    <Tag style={{ marginRight: 0 }} key={tag + Math.floor(Math.random() * 100)}>
                                        {tag.toUpperCase()}
                                    </Tag>
                                ))}
                            </Space>
                        ),
                    },
                ]
            },
        ]
    }, [getColumnSearchProps])

    const buySheetColumns = useMemo(() => {
        return [
            {
                title: 'BuySheet',
                children: [
                    {
                        title: 'Cost',
                        dataIndex: 'Cost',
                        key: 'Cost',
                        width: "85px",
                        ...getNumberFilterSorter('Cost'),
                        ...getPriceRenderer()
                    },
                    {
                        title: 'DaysSinceRestock',
                        dataIndex: 'DaysSinceRestock',
                        key: 'DaysSinceRestock',
                        width: "160px",
                        ...getNumberFilterSorter('DaysSinceRestock'),
                        ...getNumberRenderer()
                    },
                    {
                        title: 'LastRestockQty',
                        dataIndex: 'LastRestockQty',
                        key: 'LastRestockQty',
                        width: "150px",
                        ...getNumberFilterSorter('LastRestockQty'),
                        ...getNumberRenderer()
                    },
                    {
                        title: 'PurchasedDate',
                        dataIndex: 'PurchasedDate',
                        key: 'PurchasedDate',
                        width: '115px',
                    },
                    {
                        title: 'PurchasedQty',
                        dataIndex: 'PurchasedQuantity',
                        key: 'PurchasedQuantity',
                        width: "140px",
                        ...getNumberFilterSorter('PurchasedQuantity'),
                        ...getNumberRenderer()
                    },
                    {
                        title: 'Supplier',
                        dataIndex: 'Supplier',
                        key: 'Supplier',
                        width: '105px',
                        ...getColumnSearchProps('Supplier'),
                    },
                ]
            },
        ]
    }, [getColumnSearchProps])

    const tableColumns = useMemo(() => {
        const localColumns = [...columns];

        console.log('Re-rendering columns')

        if (showNameColumn) localColumns.push(...nameColumn)
        if (showRepricerColumns) localColumns.push(...repricerColumns)
        if (showOrdersColumns) localColumns.push(...ordersColumns)
        if (showInventoryColumns) localColumns.push(...inventoryColumns)
        if (showSellerSnapColumns) localColumns.push(...sellerSnapColumns)
        if (showBuySheetColumns) localColumns.push(...buySheetColumns)

        return localColumns
    }, [showNameColumn, showRepricerColumns, showOrdersColumns, showSellerSnapColumns, showBuySheetColumns, showInventoryColumns, columns, nameColumn, repricerColumns, ordersColumns, sellerSnapColumns, buySheetColumns, inventoryColumns])

    const prepareCSVData = (data) => {
        const localCsvData = data.map((obj) => {
            const {
                FBABuyBoxOwners,
                FBMBuyBoxOwners,
                YourBBHours,
                UpdateTimestamp,
                PublishTimes,
                PriceData,
                PriceListsByTime,
                SellerSnapData,
                ...destructured
            } = obj

            return destructured
        }).map((obj) => {
            const keys = [
                "ASIN", 
                "Name", 
                "CurrentYour",
                "CurrentBB", 
                "CurrentLowest", 
                "CurrentWinner", 
                "CurrentWinnerIsFBA", 
                "CurrentWinnerIsFBM", 
                "CurrentWinnerIsAZ", 
                "CurrentlyAZExists", 
                "CurrentlyBackordered", 
                "FBABuyBoxOwnersCount", 
                "FBACompetitors", 
                "FBMBuyBoxOwnersCount", 
                "FBMCompetitors", 
                "MaxBB", 
                "TimeOfMaxBB", 
                "MinBB", 
                "TimeOfMinBB", 
                "MaxLowest", 
                "MinLowest", 
                "AverageBB", 
                "AverageLowest",
                "WasSuppressed", 
                "SuppressedTime", 
                "YourBBTime", 
                "YourBBTimeWeighted", 
                "TotalTimeDiff", 
                "TotalTimeDiffWeighted", 
                "FirstNotifTimestamp", 
                "LastNotifTimestamp", 
                "TimeDiff", 
                "BBShare", 
                "WeightedBBShare", 
                "SuppressedShare",
                'SellersnapDailyShare',
                "SellersnapBBShareMonthly",
                "SellersnapBBShareWeekly",  
                "AllNotifs", 
                "BuyBoxExistingNotifs", 
                "LowestPriceExistingNotifs", 
                "30DSales", 
                "7DSales", 
                "3DSales", 
                "1DSales", 
                "AvgSales", 
                "ProjSales", 
                "InboundQuant", 
                "InstockQuant", 
                "TotalQuant", 
                "RemDays", 
                "SellerSnapExists", 
                "InStock", 
                "MaxPrice", 
                "MinPrice", 
                "Quantity", 
                "InvValue", 
                "Tags"
            ]

            const newObj = {}
            keys.forEach((key) => newObj[key] = obj[key])

            return newObj;
        })

        console.log(localCsvData)

        setCSVData(localCsvData)
    }

    return (
        <>
            <Table
                rowKey="ASIN"
                bordered={true}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => {
                            if (event.target.localName !== "a") {
                                setItem(record);
                                setVisibility(true);
                            }
                        },
                    };
                }}
                loading={loading}
                columns={tableColumns}
                scroll={{ y: "68vh" }}
                sticky
                size="small"
                tableLayout={"fixed"}
                dataSource={data}
                pagination={{
                    defaultPageSize: 20,
                    pageSizeOptions: [20, 50, 100],
                    showQuickJumper: true,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
                onChange={(pagination, filters, sorter, extra) => {
                    prepareCSVData(extra.currentDataSource)
                }}
                title={() => (
                    <Space align="center">
                        <CSVDownload collection="repricer" title="table" isLoading={loading} data={csvData}></CSVDownload>
                        <Text>Choose your column set here:</Text>
                        <Switch defaultChecked checkedChildren="Name" unCheckedChildren="Name" onChange={(status) => { console.log(status); setShowNameColumn(status) }} />
                        <Switch defaultChecked checkedChildren="Repricer" unCheckedChildren="Repricer" onChange={(status) => { setShowRepricerColumns(status) }} />
                        <Switch defaultChecked checkedChildren="Orders" unCheckedChildren="Orders" onChange={(status) => { setShowOrdersColumns(status) }} />
                        <Switch defaultChecked checkedChildren="Inventory" unCheckedChildren="Inventory" onChange={(status) => { setShowInventoryColumns(status) }} />
                        <Switch defaultChecked checkedChildren="SellerSnap" unCheckedChildren="SellerSnap" onChange={(status) => { setShowSellerSnapColumns(status) }} />
                        <Switch defaultChecked checkedChildren="BuySheet" unCheckedChildren="BuySheet" onChange={(status) => { setShowBuySheetColumns(status) }} />
                    </Space>
                )}
            />
            <Modal
                width={1500}
                title={
                    <div>
                        <Space>
                            {`${item?.Name ? `${item?.Name} (${item?.ASIN})` : item?.ASIN} details`}
                            {item?.CurrentlyBackordered ? <Tag icon={<ExclamationCircleOutlined />} color="warning">Backordered</Tag> : null}
                            {item?.SellerSnapData !== undefined ? item?.SellerSnapData.tags.map(tag =>
                                <Tag>{tag}</Tag>
                            ) : ""}
                        </Space>
                    </div>
                }
                visible={visible}
                centered
                footer={null}
                okButtonProps={{ danger: false }}
                onCancel={() => setVisibility(false)}
                destroyOnClose={true}
                bodyStyle={{ padding: "8px" }}
            >
                <ModalDetails item={item}></ModalDetails>
            </Modal>

        </>
    );
};

export default DataTable;
