import { Row, Space, Table, Typography } from "antd";
import Text from "antd/lib/typography/Text";
import dayjs from 'dayjs';
import React, { useMemo } from "react";
import { CSVDownload } from "../../CSVDownload";
import { getNumberFilterSorter } from "../../utilities/TableFilterSorters";
import PectyTableExpand from "./PectyTableExpand";
const { Link } = Typography;
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

const PectyTable = ({ data, isLoading, buttons, showTitle=false }) => {
    const columns = useMemo(() => ([
        {
            title: 'Timestamp',
            dataIndex: 'dateProcessed',
            key: 'dateProcessed',
            width: "100px",
            render: (val) => dayjs(val).tz("America/Chicago").format("MM-DD-YYYY HH:mm")
        },
        {
            title: 'Offers',
            dataIndex: 'totalOffers',
            key: 'totalOffers',
            width: "100px",
            ...getNumberFilterSorter()
        },
        {
            title: 'TotalQTY',
            dataIndex: 'totalQty',
            key: 'totalQty',
            width: "100px",
            ...getNumberFilterSorter()
        },
        {
            title: 'minPrice',
            dataIndex: 'minPrice',
            key: 'minPrice',
            width: "100px",
            ...getNumberFilterSorter()
        },
        {
            title: 'avgPrice',
            dataIndex: 'avgPrice',
            key: 'avgPrice',
            width: "100px",
            ...getNumberFilterSorter()
        },
        {
            title: 'maxPrice',
            dataIndex: 'maxPrice',
            key: 'maxPrice',
            width: "100px",
            ...getNumberFilterSorter()
        },
        {
            title: 'BB',
            dataIndex: 'buyboxSellerName',
            key: 'buyboxSellerName',
            width: "100px",
        },
    ]), [])

    const item = useMemo(() => data ? data[0] : null, [data])

    return (
        <>
            <Table
                rowKey="dateProcessed"
                bordered={true}
                loading={isLoading}
                columns={columns}
                scroll={{ y: "68vh" }}
                sticky
                size="small"
                tableLayout={"fixed"}
                dataSource={data}
                pagination={{
                    defaultPageSize: 20,
                    pageSizeOptions: [20, 50, 100],
                    showQuickJumper: true,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
                expandable={{
                    expandedRowRender: record => <PectyTableExpand record={record}></PectyTableExpand>,
                    rowExpandable: record => record.offers,
                }}
                title={() =>
                    <Row justify="space-between">
                        <Space>
                            {showTitle ? <><Link href={`https://www.amazon.com/dp/${item?.asin}/?th=1&psc=1`} target="_blank">
                            {item?.asin}
                        </Link>
                        <Text>{item?.itemName}</Text></> : <> </>}
                        </Space>
                        <Space>
                        {buttons}
                        <CSVDownload title="pecty" collection={item?.asin} data={data?.map(obj =>
                            (({ asin, itemName, dateProcessed, totalOffers, totalQty, minPrice, avgPrice, maxPrice }) =>
                                ({ asin, itemName, dateProcessed, totalOffers, totalQty, minPrice, avgPrice, maxPrice }))
                                (obj))} />
                                
                        <CSVDownload title="pecty_offers" collection={item?.asin} data={data?.[0]?.offers}>Get Offers</CSVDownload>
                        </Space>
                    </Row>
                }
            />
        </>
    );
};

export default PectyTable;
