import React from 'react';
import { PageHeader } from 'antd';
import NavButton from './NavButton';
import { useAuth } from '../../services/AuthContext';

const Header = () => {
    const { currentUser } = useAuth();
    return (
        <PageHeader
            className='site-page-header'
            title='Project: Repricer'
            extra={
                currentUser
                    ? [
                          <NavButton url='/summary' name='Summary' key='0' />,
                          <NavButton url='/reports' name='Reports' key='1' />,
                          <NavButton url='/pecty' name='Pecty' key='2' />,
                          <NavButton url='/insights' name='Insights' key='3' />,
                          <NavButton url='/logout' name='Sign out' key='4' />,
                      ]
                    : [<NavButton url='/login' name='Sign in' key='0' />]
            }
        />
    );
};

export default Header;
