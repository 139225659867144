import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '../services/AuthContext';

export default function PrivateRoute({ children, ...rest }) {
    const { currentUser } = useAuth();
    return (
        <Route {...rest}>
            {currentUser ? children : <Redirect to='/login' />}
        </Route>
    );
}
