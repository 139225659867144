import { CopyOutlined, EditOutlined, UndoOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, InputNumber, message, Popover, Row, Space, Spin, Statistic, Tabs, Tag } from "antd";
import Text from "antd/lib/typography/Text";
import React, { useCallback, useState } from "react";
import { getSingleInsight } from "../../../services/DataService";
import InsightEditor from "./modal_components/InsightEditor";
import QuantityTable from "./modal_components/QuantityTable";

const { TabPane } = Tabs;

const InsightsModal = ({ insight, updateInsights }) => {
    const [spinning, setSpinning] = useState(false)
    const [estSales, setEstSales] = useState(insight['EstSales (Manual)'] || insight['EstSales (Orders)']  || insight['EstSales (SS)'] || insight['EstSales (Keepa)'])


    const EditEstSalesPopover = useCallback((insight) => 
        (
            <InputNumber
                size="small"
                min={0}
                defaultValue={estSales}
                precision={3}
                autoFocus={true}
                onPressEnter={(e) => {
                    setSpinning(true)
                    const newEstSales = parseFloat(e.target.value);
                    insight['EstSales (Manual)'] = newEstSales;
                    getSingleInsight(insight).then(res => {
                        updateInsights(res)
                        setSpinning(false)
                    })
                    setEstSales(newEstSales)
                }}
            />
        )
    , [estSales, updateInsights])

    console.log(insight)
    return (
        <Spin spinning={false}>
            <Tabs defaultActiveKey="1" tabBarExtraContent={
                {
                    left:
                        <Space>
                            <Button style={{ marginRight: '0px' }} href={`https://www.amazon.com/dp/${insight.ASIN}/?th=1&psc=1`} target="_blank">Amazon</Button>
                            <Button onClick={() => navigator.clipboard.writeText(insight.SSData.sku).then(() => window.open("https://app.sellersnap.io/listings", "_blank"))} style={{ marginRight: '16px' }}>SellerSnap</Button>
                        </Space>,
                    right:
                        <Space direction="horizontal" align="center" style={{ width: "100%" }}>
                            <Text>Tags: </Text>
                            {insight.SSData.tags.map(tag => (
                                <Tag style={{ marginRight: 0 }} key={tag + Math.floor(Math.random() * 100)}>
                                    {tag.toUpperCase()}
                                </Tag>
                            ))}
                        </Space>
                }
            }>
                <TabPane tab="Data" key="1">
                    <Spin spinning={spinning}>
                    <Row gutter={[8, 8]} align="middle" style={{ marginBottom: '16px', justifyContent: 'center', display: 'flex' }}>
                        <Col span={8}>
                            <Card bordered={true}>
                                <Space direction="vertical" style={{ width: "100%" }}>
                                    <Space direction="horizontal" align="center" style={{ width: "100%", justifyContent: 'space-between' }}>
                                        <Statistic title={<>ASIN <CopyOutlined style={{ color: "white" }} onClick={() => navigator.clipboard.writeText(insight.SSData.asin).then(() => message.success('Copied'))} /></>} value={insight.SSData.asin} />
                                        <div>
                                            <Statistic title="Tags" precision={0} value={insight.SSData?.tags?.join(', ')} />
                                            {/* <Text>Tags: </Text> */}
                                        </div>
                                    </Space>
                                    
                                    <Statistic title={<>SKU <CopyOutlined style={{ color: "white" }} onClick={() => navigator.clipboard.writeText(insight.SSData.sku).then(() => message.success('Copied'))} /></>} value={insight.SSData.sku} />
                                    <Statistic title="Preset" value={insight.SSData.reprice_config_preset} />
                                </Space>
                            </Card>
                        </Col>
                        <Col span={4}>
                            <Card bordered={true}>
                                <Space direction="vertical">
                                    <Statistic title="Inventory" precision={0} value={insight.Inventory} />
                                    <Statistic title="Days in Stock" precision={0} value={insight.SSData.days_in_stock} />
                                    <Statistic title="BB Share" suffix={'%'} precision={2} value={insight['BBShare (%)']} />
                                </Space>
                            </Card>
                        </Col>
                        <Col span={4}>
                            <Card bordered={true}>
                                <Space direction="vertical">
                                    <Statistic title={`30D Sales (${insight['EstSales (Orders)'] > 0 ? 'MWS' : 'SS'})`} precision={0} value={Math.max(insight.SSData.total_order_items, insight.OrderData?.reduce((sales, order) => sales + parseInt(order.quantity), 0) || 0)} />
                                    <Statistic
                                        title={`EstSales (${((insight) => {
                                            if (insight['EstSales (Manual)'] > 0) {
                                                return 'based on Input'
                                            } else if (insight['EstSales (Orders)'] > 0) {
                                                return 'based on Orders (MWS)'
                                            } else if (insight['EstSales (SS)'] > 0) {
                                                return 'based on Orders (SS)'
                                            } else if (insight['EstSales (Keepa)'] > 0) {
                                                return 'based on SalesRank'
                                            } else {
                                                return 'based on nothing'
                                            }
                                        })(insight)})`}
                                        precision={2}
                                        suffix={
                                            <>
                                                <Divider type="vertical" ></Divider>
                                                <Popover
                                                    trigger="click"
                                                    content={EditEstSalesPopover(insight)}
                                                >
                                                    <EditOutlined style={{ fontSize: '16px' }} />
                                                </Popover>
                                                <Divider type="vertical" ></Divider>
                                                <UndoOutlined 
                                                    onClick={() => {
                                                        setSpinning(true)
                                                        insight['EstSales (Manual)'] = undefined;
                                                        getSingleInsight(insight).then(res => {
                                                            updateInsights(res)
                                                            setSpinning(false)
                                                        })
                                                        setEstSales(insight['EstSales (Orders)'] || insight['EstSales (SS)'] || insight['EstSales (Keepa)'])
                                                    }}
                                                    style={{ fontSize: '16px' }} 
                                                />
                                            </>
                                        }
                                        value={estSales}
                                    />
                                    <Statistic title="Average Selling Price" prefix={'$'} precision={2} value={insight.SSData.avg_selling_price || 'Unknown'} />
                                </Space>
                            </Card>
                        </Col>
                        <Col span={4}>
                            <Card bordered={true}>
                                <Space direction="vertical">
                                    <Statistic title="Your Price" prefix={'$'} precision={2} value={insight.YourPrice} />
                                    <Statistic title="Min. Price" prefix={'$'} precision={2} value={insight.MinPrice} />
                                    <Statistic title="Max. Price" prefix={'$'} precision={2} value={insight.MaxPrice} />
                                </Space>
                            </Card>
                        </Col>
                        <Col span={4}>
                            <Card bordered={true}>
                                <Space direction="vertical">
                                    <Statistic title="Cost (with shipping fee)" prefix={'$'} precision={2} value={insight.TotalCost} />
                                    <Statistic title="Profit (current, after fees)" prefix={'$'} precision={2} value={insight.YourProfit} />
                                    <Statistic title="Investment" prefix={'$'} precision={2} value={insight.TotalInvestment} />
                                </Space>
                            </Card>
                        </Col>
                    </Row>
                    <QuantityTable insight={insight}></QuantityTable>
                    </Spin>
                </TabPane>
                <TabPane tab="Change" key="2">
                    <InsightEditor insight={insight}></InsightEditor>
                </TabPane>
            </Tabs>
        </Spin>
    );
};

export default InsightsModal;
