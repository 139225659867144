import { FallOutlined, RiseOutlined, SearchOutlined, WarningFilled } from "@ant-design/icons";
import { Button, Input, Space, Table, Tooltip, Typography, Modal } from "antd";
import Text from "antd/lib/typography/Text";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { MaxSellerName } from "../../../utility/constants";
import {
    getNumberFilterSorter,
    getNumberRenderer,
    getPriceRenderer,
} from "../utilities/TableFilterSorters";
import InsightsModal from "./InisightsModal";
import InsightEditor from "./modal_components/InsightEditor";
const { Link } = Typography;
const utc = require("dayjs/plugin/utc"); // dependent on utc plugin
const timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

const renderAction = (record) => {
    const val = record.SuggestedAction;
    const amazonExists = record.OfferData?.find(offer => offer.sellerName.toLowerCase().includes("amazon.com"))
    const moqExists = record.OfferData?.find(offer => offer.moq && offer.sellerName !== MaxSellerName)
    
    return (
        <>
            {(amazonExists || moqExists) ? <>
                <Tooltip placement="topLeft" title={amazonExists ? 'Amazon offer exists' : 'MOQ exists on one of the offers'}>
                    <WarningFilled style={{color: 'red'}}/>{" "}
                </Tooltip>
                
            </> : null} 
            {val === "UP" ? (
                <Text>
                    <RiseOutlined
                        style={{color: "#49aa19" }}
                    />{" "}
                    to the moon (${record.SuggestedPrice?.toFixed(2)})
                </Text>
            ) : val === "DOWN" ? (
                <Text>
                    <FallOutlined
                        style={{ color: "#d84a1b" }}
                    />{" "}
                    dump et (${record.SuggestedPrice?.toFixed(2)})
                </Text>
            ) : (
                <Text style={{ color: "gray" }}>stay put{record.SSData?.days_in_stock < 10 ? ' (wait for more data)' : ''}</Text>
            )}
        </>
    );
};

const InsightsTable = ({ data, isLoading, buttons, showTitle = false }) => {
    const [visible, setVisibility] = useState(false);
    const [insight, setInsight] = useState(null);
    const searchRef = useRef();

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };

    const handleReset = (clearFilters) => {
        clearFilters();
    };

    const getColumnSearchProps = useCallback(
        (dataIndex) => ({
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchRef}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) =>
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                            )
                        }
                        onPressEnter={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: "block",
                        }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() =>
                                handleSearch(selectedKeys, confirm, dataIndex)
                            }
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => handleReset(clearFilters)}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered) => (
                <SearchOutlined
                    style={{ color: filtered ? "#1890ff" : undefined }}
                />
            ),
            onFilter: (value, record) =>
                record[dataIndex]
                    ? record[dataIndex]
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase())
                    : "",
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    setTimeout(() => searchRef.current?.select(), 100);
                }
            },
            render: (text) =>
                dataIndex === "ASIN" ? (
                    <Link
                        href={`https://www.amazon.com/dp/${text}/?th=1&psc=1`}
                        target="_blank"
                    >
                        {text}
                    </Link>
                ) : (
                    text
                ),
        }),
        []
    );

    const columns = useMemo(
        () => [
            {
                title: "ASIN",
                dataIndex: "ASIN",
                key: "ASIN",
                width: "70px",
                render: (ASIN) => (
                    <Link
                        href={`https://www.amazon.com/dp/${ASIN}/?th=1&psc=1`}
                        target="_blank"
                    >
                        {ASIN}
                    </Link>
                ),
                ...getColumnSearchProps("ASIN"),
            },
            {
                title: "Title",
                dataIndex: "Title",
                key: "Title",
                width: "275px",
                ...getColumnSearchProps("Title"),
                ellipsis: {
                    showTitle: false,
                },
                render: (address) => (
                    <Tooltip placement="topLeft" title={address}>
                        {address}
                    </Tooltip>
                ),
            },
            {
                title: "Pecty Timestamp",
                dataIndex: "PectyTimestamp",
                key: "PectyTimestamp",
                width: "100px",
                render: (val) =>
                    dayjs(val).tz("America/Chicago").format("MM-DD-YYYY HH:mm"),
            },
            {
                title: "Offers",
                dataIndex: "OffersCount",
                key: "OffersCount",
                width: "100px",
                ...getNumberFilterSorter("OffersCount"),
            },
            {
                title: "Inventory",
                dataIndex: "Inventory",
                key: "Inventory",
                width: "100px",
                ...getNumberFilterSorter("Inventory"),
                ...getNumberRenderer(),
            },
            {
                title: "Investment",
                dataIndex: "TotalInvestment",
                key: "TotalInvestment",
                width: "100px",
                ...getNumberFilterSorter("TotalInvestment"),
                ...getPriceRenderer(),
            },
            {
                title: "Your Price",
                dataIndex: "YourPrice",
                key: "YourPrice",
                width: "100px",
                ...getNumberFilterSorter("YourPrice"),
                ...getPriceRenderer(),
            },
            {
                title: "Suggested Action",
                dataIndex: "SuggestedAction",
                key: "SuggestedAction",
                width: "120px",
                render: (_, record) => 
                    <Space direction="vertical" size={0} align="center" style={{width: "100%" }}>
                        {renderAction(record)}
                    </Space>,
            },
            {
                title: "Extra Profit",
                dataIndex: "ProfitDiff",
                key: "ProfitDiff",
                width: "80px",
                ...getNumberFilterSorter("ProfitDiff"),
                render: (val, record) => {
                    return val === undefined || val === -1 || val === null
                        ? "Unknown"
                        : `$${parseFloat(val).toFixed(2)}`;
                },
            },
            {
                title: "Extra GMROI",
                dataIndex: "GMROIDiff",
                key: "GMROIDiff",
                width: "80px",
                ...getNumberFilterSorter("GMROIDiff"),
                ...getNumberRenderer(true)
            },
            {
                title: "Wait Period",
                dataIndex: "DaysDiff",
                key: "DaysDiff",
                width: "80px",
                ...getNumberFilterSorter("DaysDiff"),
                ...getNumberRenderer(true)
            },
            {
                title: 'Edit',
                key: 'edit',
                fixed: 'right',
                width: "50px",
                render: (val, record) => (
                    <Space direction="vertical" size="middle" align="center" style={{ width: "100%" }}>
                        <Button onClick={() => Modal.info({
                            width: '700px',
                            title: 'Update SellerSnap prices',
                            content: <InsightEditor insight={record}></InsightEditor>,
                            maskClosable: true,
                            icon: null,
                            destroyOnClose: true,
                            cancelButtonProps: { style: { display: 'none' } },
                            okButtonProps: { style: { display: 'none' } },
                            bodyStyle: { padding: "8px", height: '175px' }
                        })}>Edit</Button>
                    </Space>
                )
            }
        ],
        [getColumnSearchProps]
    );

    function updateNewInsights(state, insight) {
        if (Array.isArray(insight)) {
            return insight;
        } else {
            if (state && insight) {
                const newInsights = [...state]
                const index = newInsights.findIndex(item => insight.ASIN === item.ASIN);
                const item = newInsights[index];
        
                newInsights.splice(index, 1, {
                    ...item,
                    ...insight,
                });
        
                console.log('Saving', newInsights)
                setInsight(insight)
                return newInsights
            }
        }
    }

    const [insights, updateInsights] = useReducer(updateNewInsights, data);

    useEffect(() => {
        updateInsights(data)
    }, [data])

    console.log('Current insights', insights)

    return (
        <>
            <Table
                rowKey={(row) => row.ASIN}
                bordered={true}
                loading={isLoading}
                columns={columns}
                scroll={{ y: "68vh" }}
                sticky
                size="large"
                tableLayout={"fixed"}
                dataSource={insights?.filter(item => item.Cost !== null)}
                rowClassName={(record, index) => {
                    let classNames = []
                    
                    if (record.SuggestedAction === "STAY") {
                        classNames.push("stay-put")
                    }
                    
                    if ((record['EstSales (Keepa)'] > 0 && record.SSData.total_order_items === 0) && (!record['EstSales (Manual)'])) {
                        classNames.push("keepa-estsales")
                    }

                    return classNames.join(" ")
                }}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            if (!['a', 'span', 'button'].includes(event.target.localName)) {
                                setInsight(record);
                                setVisibility(true);
                            }
                        },
                    };
                }}
                pagination={{
                    defaultPageSize: 20,
                    pageSizeOptions: [20, 50, 100],
                    showQuickJumper: true,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                }}
            />
            <Modal
                width={1700}
                title={
                    <Space direction="vertical" size={0} align="center" style={{width: "100%" }}>
                        <Text>
                        {insight?.Title} - {insight ? renderAction(insight) : null}
                        </Text>
                    </Space>
                }
                visible={visible}
                centered
                footer={null}
                okButtonProps={{ danger: false }}
                onCancel={() => setVisibility(false)}
                destroyOnClose={true}
                bodyStyle={{ padding: "8px", minHeight: "660px" }}
            >
                <InsightsModal insight={insight} updateInsights={updateInsights}></InsightsModal>
            </Modal>
        </>
    );
};

export default InsightsTable;
