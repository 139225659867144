import React, { useContext } from "react";
import { Select } from "antd";
import { useQuery } from "react-query";
import DataContext from "../../services/DataContext";

const ASINDropdown = ({ onChange, query, queryKey = 'asins' }) => {
  const { data } = useQuery(['dropdown', queryKey], query);
  const dataRepo = useContext(DataContext)

  return (
    <Select 
      defaultValue="Choose ASIN..." 
      onChange={onChange} 
      showSearch
      filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      style={{width:"100%"}}
      >
      {data?.sort().map((collection, i) => (
        <Select.Option value={collection.asin} key={i} >
          {collection.asin} - {dataRepo.titles[collection.asin] !== undefined ? dataRepo.titles[collection.asin] : collection.title}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ASINDropdown;
