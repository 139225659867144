import { LineChart } from '@opd/g2plot-react';
import { Modal } from 'antd';
import dayjs from "dayjs";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import SellerContext, { SellerProvider } from '../../services/SellerContext';
import BuyBoxModal from './BuyBoxModal';

const renderSellerName = (sellerId, sellerNameMap) => sellerNameMap[sellerId] ? sellerNameMap[sellerId] : sellerId

const PriceChart = ({ priceData, showWinnersOnly }) => {
    const chartRef = useRef();
    const sellerNameMap = useContext(SellerContext);
    const [buyBoxChartData, setBuyBoxChartData] = useState([]);
    const [buyBoxChartWinners, setBuyBoxChartWinners] = useState([]);
    const [colorMap, setColorMap] = useState({})
    const [buyBoxChartHelpers, setBuyBoxChartHelpers] = useState({
        'min': Number.POSITIVE_INFINITY,
        'max': 0
    });

    useEffect(() => {
        const colors = ["#FF6B3B","#626681","#FFC100","#9FB40F","#76523B","#DAD5B5","#0E8E89","#E19348","#F383A2","#247FEA","#2BCB95","#B1ABF4","#1D42C2","#1D9ED1","#D64BC0","#255634","#8C8C47","#8CDAE5","#8E283B","#791DC9"]
        const localHelpers = {
            'min': Number.POSITIVE_INFINITY,
            'max': 0
        }

        const localColorMap = {}
        const sellerMap = {};

        const localPriceData = priceData.slice(0);
        const localData = []

        localPriceData.forEach(([time, offersData]) => {
            Object.entries(offersData).forEach(([seller, priceData]) => {
                sellerMap[seller] = false;
            })
        })

        localPriceData.reverse().forEach(([time, offersData]) => {
            let buyBoxWinnerFound = false;
            let buyBoxWinner = "";
            let localSellerMap = {...sellerMap};
            Object.entries(offersData).forEach(([seller, priceData]) => {
                const renderName = renderSellerName(seller, sellerNameMap);

                localData.push({
                    'time': dayjs(time).toISOString(),
                    'value': priceData.price,
                    'category': renderSellerName(seller, sellerNameMap)
                })

                localSellerMap[seller] = true;

                if (priceData.isWinner & !buyBoxWinnerFound) {
                    buyBoxWinnerFound = true;
                    buyBoxWinner = priceData.fullfillment;
                    localData.push({
                        'time': dayjs(time).toISOString(),
                        'value': priceData.price,
                        'category': `BuyBox-${priceData.fullfillment === 'Amazon' ? 'FBA' : 'FBM'}`,
                        'winner': renderName
                    })
                } else if (priceData.isWinner && buyBoxWinnerFound) {
                    if (priceData.fullfillment !== buyBoxWinner) {
                        localData.push({
                            'time': dayjs(time).toISOString(),
                            'value': priceData.price,
                            'category': `BuyBox-${priceData.fullfillment === 'Amazon' ? 'FBA' : 'FBM'}`,
                            'winner': renderName
                        })
                    }
                }

                if (priceData.price < localHelpers.min) {
                    localHelpers.min = Math.round(priceData.price * 0.95)
                }

                if (priceData.price > localHelpers.max) {
                    localHelpers.max = Math.round(priceData.price * 1.05)
                }

                if (!localColorMap[renderName]) {
                    localColorMap[renderName] = colors.shift();
                }
            })

            Object.entries(localSellerMap).forEach(([seller, wasPresent]) => {
                if (!wasPresent) {
                    localData.push({
                        'time': dayjs(time).toISOString(),
                        'value': null,
                        'category': renderSellerName(seller, sellerNameMap)
                    })
                }
            })
        })

        setBuyBoxChartData(localData)
        setBuyBoxChartWinners([localData.filter(entry => entry.winner && entry.category.includes("FBA")), localData.filter(entry => entry.winner && entry.category.includes("FBM"))])
        setBuyBoxChartHelpers(localHelpers)
        setColorMap(localColorMap)
    }, [priceData, sellerNameMap]);

    const buyBoxWinners = useMemo(() => {
        const localWinners = {}
        buyBoxChartWinners.forEach(fullfillmentCategory => {
            fullfillmentCategory.forEach(winnerChange => {
                localWinners[winnerChange.winner] = true;
            })
        })

        localWinners['BuyBox-FBA'] = true;
        localWinners['BuyBox-FBM'] = true;
        return localWinners
    }, [buyBoxChartWinners])

    // Unused for now, might come in handy later
    // const annotationData = useMemo(() => {
    //     const localAnnotations = []
    //     buyBoxChartWinners.forEach(fullfillmentCategory => {
    //         const annotations = fullfillmentCategory.filter((val, idx, arr) => {
    //             if (idx !== 0) {
    //                 if (val.value !== arr[idx - 1].value || val.winner !== arr[idx - 1].winner) {
    //                     return true;
    //                 } else {
    //                     return false;
    //                 }
    //             } else {
    //                 return true;
    //             }
    //         }).map(change => {
    //             return {
    //                 type: 'dataMarker',
    //                 position: [dayjs(change.time).toISOString(), change.value],
    //                 text: {
    //                     content: `${change.winner} - $${change.value}`,
    //                     style: {
    //                         textAlign: 'left',
    //                     },
    //                 },
    //                 line: {
    //                     length: 40,
    //                 },
    //                 point: {
    //                     style: {
    //                         fill: change.category.includes('FBA') ? 'red' : 'blue',
    //                         stroke: change.category.includes('FBA') ? 'red' : 'blue',
    //                     },
    //                 },
    //                 isVertical: true,
    //                 autoAdjust: true,
    //             }
    //         })
    //         localAnnotations.push(...annotations)
    //     })
    //     return localAnnotations
    // }, [buyBoxChartWinners])

    const buyBoxChartConfig = useMemo(() => {
        const localConfig = {
            data: showWinnersOnly ? buyBoxChartData.filter(dataPoint => Object.keys(buyBoxWinners).includes(dataPoint.category)) : buyBoxChartData,
            xField: 'time',
            yField: 'value',
            seriesField: 'category',
            connectNulls: false,
            stepType: 'hv',
            meta: {
                time: {
                    formatter: (v) => (dayjs(v).format('HH:mm:ss')),
                },
                value: {
                    formatter: (v) => `$${v.toFixed(2)}`,
                },
            },
            slider: {
                start: 0.0,
                end: 1.0,
            },
            // annotations: annotationData,
            lineStyle: ({ category }) => {
                if (category.includes('BuyBox')) {
                    return {
                        stroke: 'red',
                        lineWidth: 0.15,
                        lineDash: [4, 4],
                        opacity: 0.15,
                    };
                }
                return {
                    opacity: 0.25,
                };
            },
            colorField: 'category',
            color: ({ category }) => {
                if (category === 'BuyBox-FBA') {
                    return 'red';
                } else if (category === 'BuyBox-FBM') {
                    return 'blue'
                }
                return colorMap[category];
            },
            point: {
                shape: ({ category }) => {
                    return category.includes('BuyBox') ? 'square' : 'circle';
                },
                style: ({ category }) => {
                    return {
                        r: category.includes('BuyBox') ? 4 : 2,
                        fillOpacity: category.includes('BuyBox') ? 1 : 0.75,
                        strokeOpacity: category.includes('BuyBox') ? 0.5 : 0.25,
                        opacity: category.includes('BuyBox') ? 1 : 0,
                    };
                },
            },
            xAxis: {
                tickMethod: 'time-cat',
            },
            yAxis: {
                min: buyBoxChartHelpers.min,
                max: buyBoxChartHelpers.max
            },
        }
        
        if (buyBoxChartData.length > 0) {
            localConfig.animation = {
                appear: {
                    animation: 'fanIn',
                    duration: 0,
                },
            }
        }

        return localConfig
    }, [buyBoxChartHelpers, colorMap, buyBoxChartData, buyBoxWinners, showWinnersOnly])

    useEffect(() => {
        if (chartRef.current !== undefined) {
            // Remove after development - prevents hot reloads from spamming additional events (there's no check in the library...)
            if (!chartRef.current.chart._events["point:click"]) {
                chartRef.current.chart.on('point:click', (e) => {
                    const tempOffer = priceData.find(([time, _]) => time === e.data.data.time)
                    if (tempOffer !== undefined) {
                        Modal.info({
                            width: 1000,
                            title: `BuyBox details - ${dayjs(tempOffer[0])}`,
                            centered: true,
                            icon: null,
                            okType: null,
                            maskClosable: true,
                            content: <SellerProvider value={sellerNameMap}><BuyBoxModal offer={tempOffer[1]}></BuyBoxModal></SellerProvider>,
                        })
                    }
                })
            }
        }
    }, [chartRef, priceData, sellerNameMap])

    return (
        <div>
            <LineChart {...buyBoxChartConfig} chartRef={chartRef}>
            </LineChart>
        </div>
    );
};

export default PriceChart;
