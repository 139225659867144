import { Col, Row, Tabs } from "antd";
import InsightsImport from "../components/data/insights/InsightsImport";
import InsightsView from "../components/data/insights/InsightsView";

const { TabPane } = Tabs

const Insights = () => {
    return (
        <Row>
            <Col span={24}>
                <Tabs defaultActiveKey="2" centered type="card">
                    <TabPane tab="Import" key="1">
                        <InsightsImport />
                    </TabPane>
                    <TabPane  tab="Browse" key="2">
                        <InsightsView />
                    </TabPane>
                </Tabs>
            </Col>
            
        </Row>
    );
}

export default Insights;
